import React, { useEffect } from 'react';

const Chat = () => {
  useEffect(() => {
    // Initialize Tidio chat
    const tidioScript = document.createElement('script');
    tidioScript.src = '//code.tidio.co/oxcn5zjrz1vyhcsmwg4luqjf5ztmqasn.js'; // Replace with your actual Tidio script URL
    tidioScript.async = true;
    document.body.appendChild(tidioScript);

    return () => {
      // Cleanup when component unmounts
      document.body.removeChild(tidioScript);
    };
  }, []);

  return null; // Tidio chat will be initialized in useEffect, so no need to render anything
};

export default Chat;

