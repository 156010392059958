import React from 'react';

const ProfileScreen = () => {
    return (
        <div className="container">
            <h1>Profile</h1>
            {/* Add your dashboard content here */}
        </div>
    );
};

export default ProfileScreen;
