import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import BookingForm from '../components/BookingForm';
import BookingFormModal from '../components/BookingFormModal';
import ImageSlider from '../components/ImageSliderNew';
import SliderGallery from '../components/SliderGallery';
import DOMPurify from 'dompurify';
import DisplayText from '../components/FormatText';
import OpeningHours from '../components/OpeningHours';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
// import BookingWeek from '../components/BookingWeek'
import '../components/BookingWeek'
import LoadingSplashScreen from '../components/LoadingSplashScreen';

const EntityDetail = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	
	
	const handleLanguageChange = (language) => {
		const currentPath = window.location.pathname;
		const newPath = `/${language}${currentPath}`;
		navigate(newPath);
	};

	const { id } = useParams();
	const [entity, setEntity] = useState(null);

	useEffect(
		() => {
			fetch(process.env.REACT_APP_BASE_URL_API + `/entity/${id}`)
				.then((response) => response.json())
				.then((data) => {
					setEntity(data);
				})
				.catch((error) => {
					console.error('Error retrieving entity:', error);
					setEntity(null);
				});
		},
		[id]
		);

	if (!entity) {
		return <LoadingSplashScreen/>;
	}

	const lastname = entity && entity.lastname ? entity.lastname : '';	const featuredImage = ''; // Get the featured image URL here

	const squareMeter = []; // Get the square meter values here
	const squareMeterList = squareMeter.map((item) => (
		<a href="#" className="list-group-item">
			{item}
		</a>
	));

	const foodValue = []; // Get the food values here
	const foodArray = foodValue.map((item) => <li className="list-group-item"> {item[0]} </li>);

	const seatCount = ''; // Get the seat count value here

	const renderEquipmentSection = () => {
		if (squareMeter.length > 0) {
			return (
				<div className="mb-5">
					<div className="h5"> Equipment </div>
					<label className="mb-4">
						{entity.title} bietet dir folgendes Equipment, dass du gerne für deinen Service benutzen kannst.
					</label>
					<div className="list-group"> {squareMeterList} </div>
				</div>
			);
		}
		return null;
	};

	const renderFoodSection = () => {
		if (foodValue.length > 0) {
			return (
				<div>
					<div className="h5"> Weitere </div> <ul className="list-group"> {foodArray} </ul>
				</div>
			);
		}
		return null;
	};

	const renderSeatCountSection = () => {
		if (seatCount) {
			return (
				<div className="col d-flex align-items-start">
					<i className="bi bi-boxes fa-xl mx-3 opacity-25" />
					<div>
						<h4 className="fw-bold mb-0 fs-4">
							{seatCount}
							Plätze
						</h4>
						<p />
					</div>
				</div>
			);
		}
		return null;
	};

	const handleSubmit = (formData) => {
		// Handle the form submission logic here
		console.log('Form Data Entity:', formData);
	
	};

	const allWeekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
	//const selectedWeekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday',]
	//const availableWeekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday',]
	const isMobileScreen = () => {
		return window.innerWidth <= 767; // Adjust the breakpoint as needed
	};

	const displayAvailablesDays = (entity) => {    
		console.log('displayAvailablesDays:', entity);
		return (
			<>
				{allWeekdays.map((day) => (
					<div key={day} className={`btn-group d-block d-md-inline mb-2 me-2 ${isMobileScreen() ? "w-100" : ""}`}>
						<label
							htmlFor={day}
							className={`d-block d-md-inline text-center form-check-label px-3 py-2 rounded-pill border me-0 ${!entity.selectedWeekdays.includes(day)
								? "text-muted bg-gray opacity-50"
								: entity.selectedWeekdays.includes(day)
									? "border-dark text-dark"
									: "border-dark"
								} ${isMobileScreen() ? "w-100" : ""}`} // Add w-100 class for mobile screens
						>
							{t(day)}
						</label>
					</div>
				))}
			</>
		);
	};	

	const displayAvailablesDays2 = ({ entity }) => {
		{entity.selectedWeekdays.map((weekday, index) => {
			return (
				<div className="btn-group mb-2" key={index}>
					{/* <input
						type="checkbox"
						id={`available${weekday}`}
						name="availableWeekday"
						className="form-check-input visually-hidden"
						value={`available${weekday}`}
					/> */}
					<label
						htmlFor={`available${weekday}`}
						className="w-100 text-center form-check-label rounded-pill border border-1 border-dark me-2"
					>
						{weekday}
					</label>
				</div>
			);
		})}
	}

	const descript = entity.description;
	// const descript = 'Welcome to the profile page of Haarbar, the ultimate destination for all your hair care needs!\n\n At Haarbar, we believe that your hair is your crowning glory and deserves the very best care. Our team of expert stylists and technicians are passionate about hair and are dedicated to providing you with a personalized hair care experience that is second to none. Whether you are looking for a simple haircut, a bold new color or a complete hair makeover, our team of professionals will work with you to create a look that is uniquely yours. We use only the highest quality products and the latest techniques to ensure that your hair looks and feels its best. But our commitment to hair care doesn’t end in the salon. We also offer a range of at-home hair care products that are specially formulated to nourish and protect your hair between salon visits. At Haarbar, we believe that your hair is an expression of your individuality and we are committed to helping you express yourself in the most beautiful way possible. \n\nSo come visit us today and discover the difference that Haarbar can make in your hair care routine.';
	// const descript = entity.description.replace(/\n/g, '<br>');
	// const descript = "Please be\n not see";

	return (
		<div id="primary" className="container pt-4">
			<main id="main" className="site-main">
				<div className="container-sm">
					<div className="d-none col-12 mb-1">
						<div className="d-flex">
							{/* <h1>{entity.title}</h1>
								<div className="flex-grow-1">{entity.address}</div> */}
							{/* <div className="d-flex justify-content-end">
								<i className="bi bi-share mx-2"></i> | <i className="bi bi-heart mx-2"></i>
							</div> */}
						</div>
						{renderEquipmentSection()} {renderFoodSection()}
					</div>
					<div className="mt-2">
						{entity.images &&
							entity.images.length > 0 && (
								<SliderGallery id={`slider_${entity._id}`} _id={`${entity._id}`} images={entity.images} />
						)}
						{/* {entity.images && entity.images.length > 0 && (
                                                <ImageSlider id={`slider_${entity._id}`} _id={`${entity._id}`} images={entity.images} />
                                            )} */}
						<div className="row my-3">
							<div className="col-7 my-auto">
								<div className="fs-2 d-none d-md-block"> {/* Show as h2 on medium screens and up */}
									{entity.title} 
									{/* {t('welcome')} */}
								</div>
								<div className="fs-3 fw-bold d-block d-md-none"> {/* Show as h4 on mobile */}
									{entity.title} 
									{/* {t('welcome')} */}
								</div>
								<small className="col-10">
									{entity.street} {entity.houseNumber} - {entity.postalCode} {entity.city}
							</small>
							</div>
							<div className="col">
								<div className="d-flex justify-content-end mt-2 my-auto">
									<div className="border p-2 rounded-4 ">
									<div className="fs-2 fw-bold d-block text-center my-2 lh-1">{(Math.round(entity.rateDay * 0.8 * 4))} € <small className='0'></small></div>
									<div className="fs-5 fw-bold d-block text-center mx-0"></div>
									<small className='d-block opacity-25 text-center smaller'>Im Monatsabo 1 Tag p. Woche</small>
									<small className='d-block opacity-25 text-center smaller'>zzgl. UmSt. und Servicegebühr</small>
									</div>
									{/* <div className="col-auto d-none">
										<img
											src="https://objective-ishizaka.81-169-173-195.plesk.page/img/profile-1.png"
											alt=""
											className="rounded-circle profile-circle"
											width="60"
											height="60"
										/>
										<small className="me-3 md d-none d-md-block"> {entity.firstname} {entity.lastname.charAt(0)}. </small>
									</div> */}
								</div>
							</div>
						</div>
								
						<div className="row my-4">
							<div className="col-12">

								{/* <h3 className="">Beschreibung</h3> */}

								<div className="col-12 ">
									{console.log(typeof entity.description)}
									{descript.split('\n\n').map((paragraph, i) => {
										return (
											<p key={`para-${i}`}>
												{paragraph.split('\n').map((line, j) => {
													return (
														<React.Fragment key={`line-${j}`}>
															{line} <br />
														</React.Fragment>
													);
												})}
											</p>
										);
									})}

									<div className='d-none'>
										<h3 className="mb-4">Timeslots</h3>
										{/* {entity.openingHours} */}
										{/* <BookingWeek data={entity} /> */}
										<hr></hr>
									</div>

									{/* <div dangerouslySetInnerHTML={{ __html: formattedText }} />
                                                        <DisplayText text={descript} /> */}
								</div>
								{/* <div className="display-linebreak" style={{ whiteSpace: 'pre-line' }}>{entity.description}</div> */}
								{/* {entity.description.split("\n").map((i, key) => {
									return <div key={key}>{i}</div>;
								})} */}
							</div>
						</div>
						{/* <div>
							<button
								onClick={() => handleLanguageChange('en')}
								className="btn btn-lg btn-secondary btn-sm  text-white px-3 me-2"
							>
								English
							</button> 
							<button
								onClick={() => handleLanguageChange('fr')}
								className="btn btn-lg btn-secondary btn-sm text-white px-3 "
							>
								French
							</button>
						</div> */} 

						<hr></hr>
						<div className="">
							<h4>Verfügbarkeit</h4>
							<p className='text-muted'>Tagen an denen du diesen Cobbe Space buchen kannst.</p>
							{displayAvailablesDays(entity)}
							{/* <div className="d-md-none">
								<div className="btn-group d-block mb-2">
									<input type="checkbox" id="availableMonday" name="availableWeekday" className="form-check-input visually-hidden" value="availableMonday"/>
									<label for="availableMonday" className="w-100 text-center form-check-label rounded-pill border border-1 border-dark me-2 ">Montag</label>
								</div>
								<div className="btn-group d-block mb-2">
									<input type="checkbox" id="availableTuesday" name="availableWeekday" className="form-check-input visually-hidden" value="availableTuesday"/>
									<label for="availableTuesday" className="w-100 text-center form-check-label rounded-pill border border-1 border-dark me-2 opacity-25">Dienstag</label>
								</div>
								<div className="btn-group d-block mb-2">
									<input type="checkbox" id="availableWednesday" name="availableWeekday" className="form-check-input visually-hidden" value="availableWednesday"/>
									<label for="availableWednesday" className="w-100 text-center form-check-label rounded-pill border border-1 border-dark me-2 ">Mittwoch</label>
								</div>
								<div className="btn-group d-block mb-2">
									<input type="checkbox" id="availableThursday" name="availableWeekday" className="form-check-input visually-hidden" value="availableThursday"/>
									<label for="availableThursday" className="w-100 text-center form-check-label rounded-pill border border-1 border-dark me-2 ">Donnerstag</label>
								</div>
								<div className="btn-group d-block mb-2">
									<input type="checkbox" id="availableFriday" name="availableWeekday" className="form-check-input visually-hidden" value="availableFriday"/>
									<label for="availableFriday" className="w-100 text-center form-check-label rounded-pill border border-1 border-dark me-2 ">Freitag</label>
								</div>
								<div className="btn-group d-block mb-2">
									<input type="checkbox" id="availableSaturday" name="availableWeekday" className="form-check-input visually-hidden" value="availableSaturday"/>
									<label for="availableSaturday" className="w-100 text-center form-check-label rounded-pill border border-1 border-dark me-2 ">Samstag</label>
								</div>
								<div className="btn-group d-block mb-2">
									<input type="checkbox" id="availableSunday" name="availableWeekday" className="form-check-input visually-hidden" value="availableSunday"/>
									<label for="availableSunday" className="w-100 text-center form-check-label rounded-pill border border-1 border-dark me-2 ">Sonntag</label>
								</div>
							</div>
								
							<div className="d-none d-md-flex">
								<div className="btn-group">
									<input type="checkbox" id="availableMonday" name="availableWeekday" className="form-check-input visually-hidden" value="availableMonday"/>
									<label for="availableMonday" className="form-check-label rounded-pill border border-dark me-2 bg-dark text-white">Montag</label>
								</div>
								<div className="btn-group">
									<input type="checkbox" id="availableTuesday" name="availableWeekday" className="form-check-input visually-hidden" value="availableTuesday"/>
									<label for="availableTuesday" className="form-check-label rounded-pill border border-dark me-2 opacity-25">Dienstag</label>
								</div>
								<div className="btn-group">
									<input type="checkbox" id="availableWednesday" name="availableWeekday" className="form-check-input visually-hidden" value="availableWednesday"/>
									<label for="availableWednesday" className="form-check-label rounded-pill border border-dark me-2  bg-dark text-white">Mittwoch</label>
								</div>
								<div className="btn-group">
									<input type="checkbox" id="availableThursday" name="availableWeekday" className="form-check-input visually-hidden" value="availableThursday"/>
									<label for="availableThursday" className="form-check-label rounded-pill border border-dark me-2  bg-dark text-white">Donnerstag</label>
								</div>
								<div className="btn-group">
									<input type="checkbox" id="availableFriday" name="availableWeekday" className="form-check-input visually-hidden" value="availableFriday"/>
									<label for="availableFriday" className="form-check-label rounded-pill border border-dark me-2  bg-dark text-white">Freitag</label>
								</div>
								<div className="btn-group">
									<input type="checkbox" id="availableSaturday" name="availableWeekday" className="form-check-input visually-hidden" value="availableSaturday"/>
									<label for="availableSaturday" className="form-check-label rounded-pill border border-dark me-2 opacity-25 ">Samstag</label>
								</div>
								<div className="btn-group">
									<input type="checkbox" id="availableSunday" name="availableWeekday" className="form-check-input visually-hidden" value="availableSunday"/>
									<label for="availableSunday" className="form-check-label rounded-pill border border-dark me-2 opacity-25 ">Sonntag</label>
								</div>
							</div> */}
							<hr></hr>
						</div>

						<div className="">
							<h3>Preise</h3>
							<p className='text-muted'>Hier findest du die Preise je nach dem wieviele Tage du pro Woche buchen möchtest.</p>

							<h4 className='f'>Einzelbuchung</h4>
							<div className='fw-bold mb-5'>Tagespreis {(Math.round(entity.rateDay * 1.0))} EUR / Einmalig</div>
							
							<h4 className='f'>Monatsabos</h4>
							<p className='text-muted'>Preise ab Tage pro Woche</p>
							
							<table className="table table-bordered rounded">
								<tbody>
									{entity.additionalDailyRates.map((rateValue, index) => {
										if (rateValue) {
											return (
												<tr key={index}>
													<th className="">{index + 1} Tag / Woche</th>
													<td className="">
														<span className="d-block">{ Math.round(rateValue ) } € <small className="opacity-50 fw-normal">/ Monat</small></span>
														{/* - {Math.round(rateValue * (index + 1) * 1.06)}  */}
													</td>
												</tr>
											);
										}
										return null;
									})}
								</tbody>
							</table>
							<hr></hr>
						</div>				

						<div>
							<ul>
								{entity.allCategories && entity.allCategories.map((category, index) => (
								<li key={index}>{category}</li>
								))}
							</ul>
						</div>


						<div className=''>
							<h3 className="mb-4">Ausstattung</h3>
							<h4 className="">Arbeitsplatz</h4>
							<p className='text-muted'>Ausstattung des Arbeitsplatzes</p>
							<ul className="list-group d-flex flex-column flex-md-row mb-5">
								{/* {entity.kitchen && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Föhn</li></div>}
								{entity.chair && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Stuhl</li></div>}
								{entity.kitchen && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Computer</li></div>}
								{entity.kitchen && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Lampe</li></div>} */}
								{entity.customAccessories && entity.customAccessories.map((accessory, index) => (
									<div className="col-12 col-md-auto" key={index}>
										<li className="list-group-item rounded rounded-xl">
											<FontAwesomeIcon icon={faCheck} className='text-success'/> {accessory}
										</li>
									</div>
								))}
							</ul>
							<h4 className="">Für dich als Cobee</h4>
							<p className='text-muted'>Das kannst du mitbenutzen</p>
							<ul className="list-group d-flex flex-column flex-md-row mb-5">
								{entity.kitchen && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Küche</li></div>}
								{entity.fridge && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Kühlschrank</li></div>}
								{entity.locker && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Spind</li></div>}
								{entity.toilette && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Mitarbeitertoilette</li></div>}
							</ul>
							<hr></hr>
							<h4 className="">Für deine Kunden</h4>
							<p className='text-muted'>Dies steht für deine Kunden im Cobee Space bereit</p>
							<ul className="list-group d-flex flex-column flex-md-row mb-5">
								{/* {entity.parkingSpace && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Kundenparkplatz</li></div>} */}
								{entity.toilette && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Kundentoilette</li></div>}
								{entity.waitingroom && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Wartebereit</li></div>}
								{/* {entity.parkingSpace && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Kostenloses WLAN</li></div>} */}
								{/* {entity.toiletteClient && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Bequeme Sitzecke</li></div>} */}
								{/* {entity.waitingSpace && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Fernseher</li></div>} */}
								{/* {entity.kitchen && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Kundenbetreuung</li></div>} */}
								{/* {entity.kitchen && <div className="col-12 col-md-auto"><li className="list-group-item rounded rounded-xl"><FontAwesomeIcon icon={faCheck} className='text-success'/> Werbegeschenke</li></div>} */}
							</ul>
						</div>

						<div className='stick-bottom'>
							<BookingFormModal handleSubmit={handleSubmit} formData={{}} entity={entity} />
						</div>

					</div>

					<div className="d-none col-md-4 col-sm-12">
						<div className="d-none d-sm-block">
							<div
								className="p-4 bg-white border border-1 rounded-4 sticky-top"
								style={{ boxShadow: '0px 0px 30px -20px' }}
							> 
								{/* <BookingForm entity={entity} /> */}
								{/* <BookingFormModal entity={entity}/>  */}
							</div>
						</div>
								
						<div className="border border-1 rounded-4 mt-4 p-4">
							<i className="bi bi-tag-fill" style={{ color: 'aquamarine' }} />
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="aquamarine"
								viewBox="0 0 24 24"
								color="green"
								strokeWidth={1.5}
								stroke="currentColor"
								className="d-inline col-1"
								style={{ width: '20px', height: '20px', marginRight: '5px' }}
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z"
								/>
								<path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6z" />
							</svg>
							<strong className="fw-bold"> Niedrigerer Preis </strong>. Der Preis für deinen Zeitraum ist 
							<strong className="text-success fw-bold ms-1 me-1" style={{ color: 'aquamarine' }}>
								{entity.rateHour}€
							</strong>
							niedriger als der durchschnittliche Preis pro Platz in den letzten 60 Tagen.
						</div>
						<div className="row">
							<div className="col">
								<div className="row">
									<div className="col">
										<div className="col-10 mt-5">
											<div className="d-flex align-items-center">
												<i className="bi bi-clock fa-xl me-3 opacity-25" />
												<div>
													{/* <h4 className="mb-0">Öffnungszeiten</h4> */} <OpeningHours />
													<p />
												</div>
											</div>
										</div>
										<div className="col-10 mt-5">
											<div className="d-flex align-items-center">
												<i className="bi bi-phone fa-xl me-3 opacity-25" />
												<div>
													<h4 className=""> Kontakt </h4>
													Haarbar Studio <br />
													+49 000 000000 <p />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<BookingFormModal handleSubmit={handleSubmit} formData={{}} entity={entity} />
						{renderSeatCountSection()}
					</div>
				</div>
			</main>
		</div>
	);
};

export default EntityDetail;
