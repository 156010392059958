import React from 'react';

function formatText(text) {
    return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
}

function DisplayText({ text }) {
    const formattedText = formatText(text);

    return <div>{formattedText}</div>;
}

export default DisplayText;
