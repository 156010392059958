import React, { useState } from 'react';
import SearchForm from '../components/SearchForm';
import BookingFormModal from '../components/BookingFormModal';

const Footer = (entity) => {
    const footerStyles = {
        position: 'relative',
        marginTop: 'auto',
        backgroundColor: '',
        padding: '20px',
        textAlign: 'center',
    };

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (query) => {
        setSearchQuery(query);
    };


    return (
        <>
            <footer class="container d-flex flex-wrap justify-content-between align-items-center py-2 mt-5 bg-light">
                <p class="col-md-4 mb-0 text-body-secondary">© 2023 Cobee GbR</p>

                {/* <a href="/" class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                </a> */}

                <ul class="nav col-md-8 justify-content-end">
                    <li class="nav-item"><a href="/privacy-policy" class="nav-link px-2 text-body-secondary">Datenschutzerklärung</a></li>
                    <li class="nav-item"><a href="/terms-conditions" class="nav-link px-2 text-body-secondary">AGB</a></li>
                    <li class="nav-item"><a href="/imprint" class="nav-link px-2 text-body-secondary">Impressum</a></li>
                </ul>
            </footer>

            {/* <footer style={footerStyles}>
                <small className="container opacity-25">
                    <span>&copy;</span> 2023 Cobee Company. All rights reserved.
                    <div className='d-block'>
                        <a href="/bookings" className='d-inline'>Bookings</a> · <a href="/login" className='d-inline'>Anmelden</a>
                    </div>
                </small>
            </footer> */}
            <div id="BookingFormInsert"></div>
        </>
    );
};

export default Footer;
