import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './ImageSlider.css';
import { useState } from 'react';

export default function ImageSlider({ id, _id, images }) {
    const [currentSlide, setCurrentSlide] = useState(0);

    const goToSlide = (index) => {
        setCurrentSlide(index);
    };

    const renderDots = () => {
        return images.map((_, index) => (
            <span
                key={index}
                className={`dot ${index === currentSlide ? 'active' : ''}`}
                onClick={() => goToSlide(index)}
            ></span>
        ));
    };

    return (
        <div className="image-slider" id={id}>
            <Swiper
                pagination={{ clickable: true }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper rounded-3"
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img src={process.env.REACT_APP_BASE_URL_API + `/uploads/${_id}/${image}`} alt={`Slide ${index + 1}`} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
