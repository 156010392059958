import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChair, faCube } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function RoomSeatSelector({ id, onEntityTypeChange, selectedEntity }) {
  console.log(selectedEntity);
  const { t } = useTranslation(); // Access the translation function
  const [selectedRoomOption, setSelectedRoomOption] = useState(selectedEntity);

  // Define the options in an array
  const options = [
    { value: 'seat', label: t('Seat'), icon: faChair },
    { value: 'room', label: t('Room'), icon: faCube },
  ];

  const handleOptionChange = (e) => {
    const optionValue = e.target.value;
    setSelectedRoomOption(optionValue);
    onEntityTypeChange(optionValue);
  };

  useEffect(() => {
    setSelectedRoomOption(selectedEntity);
  }, [selectedEntity]);

  return (
    <div className="d-flex gap-3">
      {options.map((option, index) => (
        <div key={index} className="col">
          <input
            type="radio"
            id={`${option.value}Option-${id}`}
            name={`option-${id}`}
            value={option.value}
            checked={selectedRoomOption === option.value}
            className="form-check-input visually-hidden"
            onChange={handleOptionChange}
          />
          <label
            htmlFor={`${option.value}Option-${id}`}
            className={`w-100 py-5 form-check-label rounded-4 border border-1 text-center ${
              selectedRoomOption === option.value ? 'selected' : ''
            }`}
          >
            <div className="d-flex align-items-center justify-content-center mb-2">
              <FontAwesomeIcon icon={option.icon} size="4x" />
            </div>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
}

export default RoomSeatSelector;