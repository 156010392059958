import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faBrain, faHeart, faHouse, faUserNurse, faAnchor, faDroplet, faChair, faSpa, faBottleDroplet, faHandSparkles, faSeedling, faJugDetergent, faW, faYinYang, faScissors, faPen } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function CategorySelector({ selectedCategories, onCategoryChange }) {
  const { t } = useTranslation(); // Access the translation function
  
  // Define the options in an array
  const options = [
    { value: 'beautySalon', label: t('Beauty'), icon: faDroplet },
    { value: 'hairStudio', label: t('Friseur'), icon: faScissors },
    { value: 'massage', label: t('Massage'), icon: faSpa },
    { value: 'nagelstudio', label: t('Nagelstudio'), icon: faHandSparkles },
    { value: 'naturheilpraxis', label: t('Naturheilpraxis'), icon: faSeedling },
    { value: 'physiotherapie', label: t('Physiotherapie'), icon: faBrain },
    { value: 'psychologie', label: t('Psychologie'), icon: faBrain },
    { value: 'tattoo', label: t('Tattoo Studio'), icon: faPen },
    { value: 'waxing', label: t('Waxing'), icon: faW },
    { value: 'yoga', label: t('Yoga'), icon: faYinYang },
  ];

  const handleCategoryChange = (event) => {
    const { value, checked } = event.target;
    let updatedSelectedCategories;

    if (checked) {
      updatedSelectedCategories = [...selectedCategories, value];
    } else {
      updatedSelectedCategories = selectedCategories.filter(category => category !== value);
    }

    onCategoryChange(updatedSelectedCategories);
  };

  return (
    <div className="d-grid gap-3">
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
        {options.map((option, index) => (
          <div key={index} className="col mb-4">
            <input
              type="checkbox" // Change input type to checkbox
              id={`${option.value}Option-${option.value}`}
              name={`categoryOption-${option.value}`}
              value={option.value}
              checked={selectedCategories.includes(option.value) ? 'checked' : undefined} // Check if the category is selected
              className="form-check-input visually-hidden"
              onChange={handleCategoryChange}
            />
            <label
              htmlFor={`${option.value}Option-${option.value}`}
              className={`w-100 py-5 form-check-label rounded-4 border border-1 text-center ${
                selectedCategories.includes(option.value) ? 'selected' : ''
              }`}
            >
              <div className="d-flex align-items-center justify-content-center mb-2">
                <FontAwesomeIcon icon={option.icon} size="4x" style={{ color: 'orange' }} />
              </div>
              {/* {console.log(selectedCategories)} */}
              {/* {option.value} */}
              
              {selectedCategories.includes(option.value)}
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CategorySelector;

