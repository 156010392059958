import React from 'react';

const Imprint = () => {
  return (
    <div className="container mt-4">
      <h1>Impressum</h1>

        <h4>Angaben gemäß § 5 TMG (Telemediengesetz):</h4>
        <strong>Cobee.me | Munk & Vasmer GbR i.G.</strong>
        <p>Rykestraße 52 - 10405 Berlin</p>

        <strong>Vertreten durch:</strong>
        <p>Festime Vasmer und Markus Munk (Gesellschafter)</p>

        <strong>Kontakt:</strong>
        <p>Telefon: +49 160 9777 62 94
        E-Mail: info@cobee.me</p>

        <strong>Umsatzsteuer-ID:</strong>
        <p>Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: folgt</p>

        <strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV (Rundfunkstaatsvertrag):</strong>
        <div>Markus Munk
        Linienstraße 145
        10115 Berlin</div>

        <strong>Informationen zur Online-Streitbeilegung:</strong>
        <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>.</p>
        <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

        <strong>Gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG):</strong>

        <p>Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder bereit noch verpflichtet.</p>
      
    </div>
  );
};

export default Imprint;
