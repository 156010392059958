import React, { useState, useEffect, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import Swal from 'sweetalert2';
import WeekdaySelector from '../components/WeekdaysSelector';
import TimeSelector from '../components/TimeSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import CurrencyFormat from 'react-currency-format';
import Timeslots from '../components/Timeslots'
import RoomSeatSelector from '../components/RoomSeatSelector'
import { useTranslation } from 'react-i18next';
import CategorySelector from '../components/CategorySelector';
import DayRateForm from '../components/DayRateForm'
import CustomAccessoriesComponent from '../components/addCustomAccessories'; 

const AddEntity = () => {
    const { t } = useTranslation(); // Access the translation function

    const [step, setStep] = useState(1);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [pictures, setPictures] = useState([]);
    const [previewURLs, setPreviewURLs] = useState([]);
    const [filenames, setFilenames] = useState([]);
    const [squaremeter, setSquaremeter] = useState([]);

    const [location, setLocation] = useState([]);
    const [city, setCity] = useState([]);
    const [district, setDistrict] = useState([]);
    const [branches, setBranches] = useState([]);
    const [totalSeatCount, setTotalSeatCount] = useState([]);
    const [rateDay, setRateDay] = useState(0);
    const [rateHalfDay, setRateHalfDay] = useState([]);
    const [rateHour, setRateHour] = useState([]);
    const [kitchen, setKitchen] = useState([]);
    const [fridge, setFridge] = useState([]);
    const [heatlamp, setHeatlamp] = useState([]);
    const [towel, setTowel] = useState([]);
    const [amp, setAmp] = useState([]);
    const [massageliege, setMassageliege] = useState([]);
    const [water, setWater] = useState([]);
    const [coffee, setCoffee] = useState([]);
    const [tea, setTea] = useState([]);
    const [juices, setJuices] = useState([]);
    const [otherDrinks, setOtherDrinks] = useState([]);
    const [locker, setLocker] = useState([]);
    const [toilette, setToilette] = useState([]);
    const [waitingroom, setWaitingroom] = useState([]);
    const [parkingSpace, setParkingSpace] = useState([]);
    // State for form fields

    const [dailyRate, setDailyRate] = useState(0);
    const [discount2Day, setDiscount2Day] = useState(0);
    const [discount3Day, setDiscount3Day] = useState(0);
    const [discount4Day, setDiscount4Day] = useState(0);
    const [discount5Day, setDiscount5Day] = useState(0);
    const [discount6Day, setDiscount6Day] = useState(0);
    const [discount7Day, setDiscount7Day] = useState(0);

    const [street, setStreet] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [salutation, setSalutation] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');

    const [selectedWeekdays, setSelectedWeekdays] = useState([]);

    const handleWeekdayChange = (newSelectedWeekdays) => {
        // if (Array.isArray(newSelectedWeekdays)) {
        //     newSelectedWeekdays = Object.assign({}, newSelectedWeekdays);
        // }
        setSelectedWeekdays(newSelectedWeekdays);
        setFormData({ ...formData, selectedWeekdays: newSelectedWeekdays });
        console.log(newSelectedWeekdays)
        console.log(formData)
    };

    const [selectedEntity, setSelectedEntity] = useState('');

    const handleEntityTypeChange = (entity) => {
        console.log('Space / Room selection: ' + entity);
        setSelectedEntity(entity);
        console.log(entity);
    };

    const handleDayRatesChange = (newDayRates) => {
        // Verarbeiten Sie die neuen Tagespreise hier
        setFormData((prevFormData) => ({
            ...prevFormData,
            dayRates: newDayRates,
            dayRate: initialDailyRate,
        }));
    };
    const [selectedCategories, setSelectedCategories] = useState('');
    const [currentCategories, setCurrentCategories] = useState('');

    const handleCategoryChange = (category) => {
        // if (Array.isArray(category)) {
        //     category = Object.assign({}, category);
        // }
        setSelectedCategories(category);
        setFormData({ ...formData, selectedCategories: category });
        console.log(category);
    };

    const handleDrop = (acceptedFiles) => {
        const fileURLs = acceptedFiles.map((file) => URL.createObjectURL(file));
        setPictures(acceptedFiles);
        setPreviewURLs(fileURLs);
        setFilenames(acceptedFiles.map((file) => file.name));
    };

    // Define the deletePicture function in your component
    const deletePicture = (index) => {
        // Implement the logic to delete the picture from the filenames and previewURLs arrays
        const updatedFilenames = [...filenames];
        updatedFilenames.splice(index, 1);
        setFilenames(updatedFilenames);

        const updatedPreviewURLs = [...previewURLs];
        updatedPreviewURLs.splice(index, 1);
        setPreviewURLs(updatedPreviewURLs);
    };

    // Use a state object to store all form data
    // const [formData, setFormData] = useState({});
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        entityType: '',
        selectedWeekdays: '',
        selectedCategories: '',
        firstname: '',
        lastname: '',
        email: '',
        telephone: '',
        street: '',
        houseNumber: '',
        postalCode: '',
        city: '',
        district: '',
        salutation: '',
        location: '',
        branches: '',
        totalSeatCount: '',
        rateDay: '',
        rateHalfDay: '',
        rateHour: '',
        kitchen: false,
        fridge: false,
        heatlamp: false,
        towel: false,
        amp: false,
        massageliege: false,
        water: false,
        coffee: false,
        tea: false,
        juices: false,
        otherDrinks: false,
        locker: false,
        toilette: false,
        waitingroom: false,
        parkingSpace: false,
        dailyRate: 0,
    });

    const [acceptTerms, setAcceptTerms] = useState(false);
    const [optInMarketing, setOptInMarketing] = useState(false);

    const handleAcceptTermsChange = (event) => {
        setAcceptTerms(event.target.checked);
    };

    const handleOptInMarketingChange = (event) => {
        setOptInMarketing(event.target.checked);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Form submitted executed"); // Debugging line

        const formDataObj = new FormData();
        //const formDataObj = new FormData();
        for (const key in formData) {
            formDataObj.append(key, formData[key]);
        }
        
        const additionalDailyRatesArray = Object.values(additionalDailyRates);

        console.log('additionalDailyRates on Submit', additionalDailyRates)
        console.log('additionalDailyRatesArray on Submit', additionalDailyRatesArray)
        formDataObj.append('additionalDailyRates', additionalDailyRatesArray);
        // formData.set('title', title);
        // formData.set('status', 'pending');
        // formData.set('entityType', selectedEntity);
        // formData.set('description', description);
        // formDataObj.set('selectedCategories', selectedCategories);

        formDataObj.append('selectedCategories  ', selectedCategories);
        formDataObj.append('selectedCategories', JSON.stringify(selectedCategories));
        // formDataObj.append('selectedWeekdays', selectedWeekdays);
        formDataObj.append('selectedEntity', selectedEntity);
        formDataObj.append('rateDays', JSON.stringify(formData.rateDays));
        
        formDataObj.append('rateDays', JSON.stringify(formData.rateDays));
        formDataObj.append('rateDay', formData.rateDay); // Hinzufügen von rateDay zu formDataObj

        pictures.forEach((picture) => {
            formDataObj.append('images', picture);
        });
        formDataObj.append('filenames', JSON.stringify(filenames));


        console.log('REACT_APP_BASE_URL_API: ' + process.env.REACT_APP_BASE_URL_API);
        console.log('formData:', formData);
        console.log('formDataObj:', formDataObj);

        try {
            console.log('Form Try Pressed:  ');
            const response = await axios.post(process.env.REACT_APP_BASE_URL_API + '/add', formDataObj, {
                headers: {
                    'Content-Type': 'aaplication/json',
                },
            });
            console.log('response: ' + response);
            console.log('response.data: ' + response.data);

            console.log("API Response:", response); // Log the response from the

            // Display SweetAlert2 success message
            Swal.fire({
                icon: 'success',
                title: 'Perfekt!',
                text: 'Das ging direkt durch.',
            }).then(() => {
                // Redirect to a new page
                window.location.href = '/'; // Replace with your desired URL
            });

            // Handle the response
            console.log(response.data);
        } catch (error) {
            // Handle the error
            console.error("API Error:", error.response || error); // Log the error response
        
            // Display SweetAlert2 error message
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Bitte fülle alle Felder vollständig aus.',
                background: '#black',
            });
        
            // Read and log the error response if it exists
            if (error.response) {
                console.error("Error Response Data:", error.response.data);
            } 
        
            console.error("Full Error Object:", error);
        }
        
    };

    // Funktion zum Aktualisieren von rateDay in formData
    const updateRateDay = (newRateDay) => {
        console.log('updateRateDay aufgerufen mit', newRateDay); // Überprüfen Sie, ob die Funktion aufgerufen wird und welcher Wert übergeben wird

        setFormData(prevState => ({
            ...prevState,
            rateDay: newRateDay,
        }));
    };

    // State for the initial daily rate
    const [initialDailyRate, setInitialDailyRate] = useState(0);
    const [additionalDailyRates, setAdditionalDailyRates] = useState({});
    const [additionalDailyRatesArray, setAdditionalDailyRatesArray] = useState([]);

    const handleInitialDailyRateChange = (value) => {
        setInitialDailyRate(value);
        const newAdditionalRates = {}; // Assuming this is the desired logic
        selectedWeekdays.forEach((weekday, index) => {
            newAdditionalRates[index] = calculateRate(index, value); // Use your rate calculation logic
        });
        setAdditionalDailyRates(newAdditionalRates);
        setRateDay(value); // Use the updated value directly
        console.log('rateDay: ' + initialDailyRate);

        // Update additionalDailyRates based on new initialDailyRate
    };

    const calculateRate = (index, rate) => {
        // Your existing calculation logic
        // Define initial percentage decreases for each day
    const percentageDecreases = [20, 25, 30, 35, 40, 45, 50];
        return Math.round(rate * (index + 1) * 4 * (1 - percentageDecreases[index] / 100));
    };

    const handleAdditionalDailyRatesChange = (index, value) => {
        setAdditionalDailyRates({
            ...additionalDailyRates,
            [index]: value
        });
    };

    const handleCustomAccessoriesChange = (newCustomAccessories) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            customAccessories: newCustomAccessories,
        }));
        console.log(newCustomAccessories);
        console.log(formData);
    };

    const onAdditionalDailyRatesChange = useCallback((index, value) => {
        setAdditionalDailyRates(prevRates => ({
            ...prevRates,
            [index]: value
        }));
        
        // const additionalDailyRatesArray = getAdditionalDailyRatesValues();
        
    }, [setAdditionalDailyRates]);

    // Replace individual useState calls with a function to update formData state
    const handleInputChange = (name, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleCheckboxChange = (name, checked) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: checked
        }));
    };

    // const isDevEnvironment = process.env.NODE_ENV === 'development';
    // console.log('isDevEnvironment: ' + isDevEnvironment);
    // const enableCanProceedCheck = isDevEnvironment ? true : false;

    const nextStep = () => {
        const enableCanProceedCheck = false; // Set to true or false to enable/disable the canProceedToNextStep check
        console.log(formData);
        if (!enableCanProceedCheck || canProceedToNextStep(step)) {
            setStep((prevStep) => {
                window.scrollTo(0, 0); // Jump to the top of the screen
                return prevStep + 1;
            });
        } else {
            alert("Please fill all required fields before proceeding.");
        }

    };

    const prevStep = () => {
        console.log(formData);
        setStep((prevStep) => {
            window.scrollTo(0, 0); // Jump to the top of the screen
            return prevStep - 1;
        });
    };

    const canProceedToNextStep = (currentStep) => {
        switch (currentStep) {
            case 1:
                // Add validation logic for step 1
                return '' == '';
            case 2:
                // Add validation logic for step 2
                return selectedEntity !== '';
            case 3:
                // Add validation logic for step 3
                return title !== '' && street !== '' && houseNumber !== '' && postalCode !== '' && city !== '';
            // ... and so on for each step
            case 4:
                console.log('check case 4: selectedWeekdays: ' + selectedWeekdays.length);
                return selectedWeekdays.length !== 0;
            case 5:
                return initialDailyRate !== 0;

            default:
                return true;
        }
    };

    const renderAdditionalOptions = () => {

        const amenities = [
            { name: "massageliege", label: "Massageliege", description: "Du bietest eine Massageliege an." },
            { name: "towel", label: "Handtücher", description: "Du bietest Handtücher an." },
            { name: "heatlamp", label: "Wärmelampe", description: "Du bietest eine Wärmelampe an." },
            { name: "amp", label: "Musikanlage", description: "Du bietest eine Musikanlage an." },
        ];

        return (
            <>
                <div className="form-group row mb-3">
                    <div className="col-sm-5 mb-2">Welche Ausstattung hat der Arbeitsplatz/Arbeitsraum?</div>
                    <div className="col-sm-7">
                        {/* {amenities.map((additional) => (
                            <div className="form-group form-check" key={additional.name}>
                                <label htmlFor={additional.name} className="form-check-label">{additional.label}</label>
                                <input
                                    type="checkbox"
                                    name={additional.name}
                                    id={additional.name}
                                    className="form-check-input"
                                    checked={formData[additional.name]}
                                    onChange={(e) => {
                                        handleCheckboxChange(additional.name, e.target.checked);
                                        setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            [additional.name]: e.target.checked,
                                        }));
                                    }}
                                />
                                <div className="form-text">{additional.description}</div>
                            </div>
                        ))}
                         */}
                        <CustomAccessoriesComponent onCustomAccessoriesChange={handleCustomAccessoriesChange} />                        
                    </div>
                </div>
            </>
        );
    };

    const renderAdditionalOptionsII = () => {

        const additionalAmenities = [
            { name: "parkingSpace", label: "Kundenparkplatz", description: "Den Kunden deines Cobees steht ein Kundenparkplatz zur Verfügung." },
            { name: "toilette", label: "Kundentoilette", description: "Ihr verfügt über eine Mitarbeiter- und Kundentoilette." },
            { name: "waitingroom", label: "Wartebereich", description: "Du bietest deinen Cobees einen Wartebereich für die Kunden an." },
            { name: "internet", label: "W-LAN (Kostenlos)", description: "Du bietest den Kunden deines Cobees einen Internet Hotspot an." },
        ];

        return (
            <>
                <div className="form-group row mb-3">
                    <div className="col-sm-5 mb-2">Was können die Kunden des Cobees erwarten?</div>
                    <div className="col-sm-7">
                        {additionalAmenities.map((amenity) => (
                            <div className="form-group form-check" key={amenity.name}>
                                <label htmlFor={amenity.name} className="form-check-label">{amenity.label}</label>
                                <input
                                    type="checkbox"
                                    name={amenity.name}
                                    id={amenity.name}
                                    className="form-check-input"
                                    checked={formData[amenity.name]}
                                    onChange={(e) => {
                                        handleCheckboxChange(amenity.name, e.target.checked);
                                        setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            [amenity.name]: e.target.checked,
                                        }));
                                    }}
                                />
                                <div className="form-text">{amenity.description}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        )
    };

    const cobeeResources = [
        { name: "locker", label: "Stauraum", description: "Du bietest deinen Cobees einen Stauraum für ihre Gegenstände an." },
        { name: "clienttoilette", label: "Toilette", description: "Ihr verfügt über eine Mitarbeiter- und Kundentoilette." },
        { name: "kitchen", label: "Küche", description: "Den Kunden deines Cobees steht ein Kundenparkplatz zur Verfügung." },
        { name: "fridge", label: "Kühlschrank", description: "Dein Cobee hat einen Platz in deinem Kühlschrank." },
    ];

    const renderCobeeResources = () => {
        return (
            <div className="form-group row mb-3">
                <div className="col-sm-5 mb-2">Auf was kann der Cobee zurückgreifen?</div>
                <div className="col-sm-7">
                    {cobeeResources.map((resource) => (
                        <div className="form-group form-check" key={resource.name}>
                            <label htmlFor={resource.name} className="form-check-label">{resource.label}</label>
                            <input
                                type="checkbox"
                                name={resource.name}
                                id={resource.name}
                                className="form-check-input"
                                checked={formData[resource.name]}
                                onChange={(e) => {
                                    handleCheckboxChange(resource.name, e.target.checked);
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        [resource.name]: e.target.checked,
                                    }));
                                }}
                            />
                            <div className="form-text">{resource.description}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const gatherInputValues = () => {
        return (
            <div>
                <h3>{title}</h3>
                <small className="d-block opacity-50">Beschreibung</small>
                <p>{description}</p>
                <small className="d-block opacity-50">Art</small>
                <p>{t(selectedEntity)}</p>
                <hr className='my-2'></hr>
                <small className="d-block opacity-50">Adresse</small>
                <div className="d-block">{salutation} {firstname} {lastname}</div>
                {`${street}, ${houseNumber}, ${postalCode}, ${city}`}
                <hr className='my-2'></hr>
                <div className="row">
                    <div className="col">
                        <small className="d-block opacity-50">Telefon</small>
                        <div className='d-block'>{telephone}</div>
                    </div>
                    <div className="col">
                        <small className="d-block opacity-50">Email</small>
                        <div className='d-block'>{email}</div>
                    </div>
                </div>
                <hr className='my-2'></hr>
                <div className="fs-4 fw-bold mb-1 mt-5">Tagespreis</div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="d-block fw-bold text-center">Einzelbuchung</div>
                    {/* <span className='d-block fw-bold text-center'>{initialDailyRate} EUR</span> */}
                    <div className='text-end w-100'>
                        <span className='d-block fw-bold fs-5'>{initialDailyRate} €</span>
                        <small className='d-block fw-thin opacity-50'>einmalig</small> 
                    </div>
                </div>
               
                <div className="fs-4 fw-bold mb-1 mt-5">Monatsmiete</div>
                {Object.entries(additionalDailyRates).map(([property, rate], index) => (
                    <div key={index} className="d-flex justify-content-between align-items-center mb-2">
                        <div className="text-start w-100">
                            <span className='d-block fw-bold'>{index + 1} Tage pro Woche</span>
                            {/* <span className='d-block fw-thin opacity-50'>{index + 1 * 4 } bis Tage pro Monat</span>                 */}
                        </div>
                        <div className="text-end w-100">
                            <span className='d-block fw-bold fs-5'>{rate} €</span>
                            <small className="d-block fw-thin opacity-50">p. Monat</small>
                        </div>
                        <hr className='my-2 border'></hr>
                    </div>
                ))}
                {/* For Weekdays */}
                {selectedWeekdays.length > 0 && (
                    <>
                        <div className='fs-4 fw-bold mb-1 mt-5'>Wochentage</div>
                        <ul className="my-4">
                            {selectedWeekdays.map((day, index) => (
                                <li key={index} className="d-inline rounded rounded-xl my-2 border rounded-pill py-2 px-4 small me-2">{t(day)}</li>
                            ))}
                        </ul>
                    </>
                )}

                {selectedCategories && (
                    <>
                        <div className="fs-4 fw-bold mb-1 mt-5">Kategorien</div>
                        <div className="my-4">
                            {selectedCategories.map((gallery, index) => (
                                <div key={index} className="d-inline rounded rounded-xl my-2 border rounded-pill py-2 px-4 small me-2">{t(gallery)}</div>
                            ))}
                        </div>
                    </>
                )}

                <div className="fs-4 fw-bold mb-1 mt-5">Raumausstattung</div>
                <ul className="mb-4">
                    {formData.customAccessories && formData.customAccessories.length > 0 && (
                        <>
                            {formData.customAccessories.map((accessory: string, index: number) => (
                                <span key={index} className="d-block rounded rounded-xl my-2">
                                    <FontAwesomeIcon icon={faCheck} className='text-success'/> {accessory}
                                </span>
                            ))}
                        </>
                    )}
                </ul>

                <div className="fs-4 fw-bold mb-1 mt-5">Für Kunden des Mieters</div>

                <ul className="mb-4">
                    {formData.parkingSpace && (
                        <span className="d-block rounded rounded-xl my-2">
                            <FontAwesomeIcon icon={faCheck} className='text-success'/> Kundenparkplatz {formData.parkingSpace}
                        </span>
                    )}
                    {formData.toilette && (
                        <span className="d-block rounded rounded-xl my-2">
                            <FontAwesomeIcon icon={faCheck} className='text-success'/> Toilette {formData.toilette}
                        </span>
                    )}
                    {formData.waitingroom && (
                        <span className="d-block rounded rounded-xl my-2">
                            <FontAwesomeIcon icon={faCheck} className='text-success'/> Wartebereich {formData.waitingroom}
                        </span>
                    )}
                    {formData.internet && (
                        <span className="d-block rounded rounded-xl my-2">
                            <FontAwesomeIcon icon={faCheck} className='text-success'/> W-LAN (Kostenlos) {formData.internet}
                        </span>
                    )}
                </ul>

                {/* For Dich als Mieter */}
                <div className="fs-4 fw-bold mb-1 mt-5">Für den Mieter</div>
                <ul className=" mb-4">
                    {formData.locker && (
                        <li className="d-block rounded rounded-xl my-2">
                            <FontAwesomeIcon icon={faCheck} className='text-success'/> Privater Stauraum {formData.locker}
                        </li>
                    )}
                    {formData.kitchen && (
                        <li className="d-block rounded rounded-xl my-2">
                            <FontAwesomeIcon icon={faCheck} className='text-success'/> Küche {formData.kitchen}
                        </li>
                    )}
                    {formData.toilette && (
                        <li className="d-block rounded rounded-xl my-2">
                            <FontAwesomeIcon icon={faCheck} className='text-success'/> Toilette {formData.toilette}
                        </li>
                    )}
                    {formData.fridge && (
                        <li className="d-block rounded rounded-xl my-2">
                            <FontAwesomeIcon icon={faCheck} className='text-success'/> Kühlschrank {formData.fridge}
                        </li>
                    )}
                </ul>
                {/* {formData.locker && <p className="badge badge-pill bg-dark rounded-4 me-2"><strong>Spind</strong> {formData.locker}</p>} */}

                {/* {formData.water && <p className="badge badge-pill bg-dark rounded-4 me-2"><strong>Wasser</strong> {formData.water}</p>}
                {formData.coffee && <p className="badge badge-pill bg-dark rounded-4 me-2"><strong>Kaffee</strong> {formData.coffee}</p>}
                {formData.tea && <p className="badge badge-pill bg-dark rounded-4 me-2"><strong>Tee</strong> {formData.tea}</p>}
                {formData.juices && <p className="badge badge-pill bg-dark rounded-4 me-2"><strong>Säfte</strong> {formData.juices}</p>}
                {formData.otherDrinks && <p className="badge badge-pill bg-dark rounded-4 me-2"><strong>Andere Getränke</strong> {formData.otherDrinks}</p>} */}

                {/* For Uploaded Pictures */}

                {/* {
                    filenames.length > 0 && (
                        <>
                            <p><strong>Hochgeladene Bilder</strong></p>
                            <ul>
                                {filenames.map((filename, index) => (
                                    <li key={index}>
                                        <img src={"../" + filename} alt={filename} />
                                    </li>
                                ))}
                            </ul>
                        </>
                    )
                } */}

                <hr></hr>
            
                {/* Checkbox for accepting terms and conditions */}
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="acceptTermsCheckbox"
                        checked={acceptTerms}
                        onChange={handleAcceptTermsChange}
                    />
                    <label className="small mb-2 opacity-50 col-8">
                        Hiermit bestätige ich, dass ich die <a className="fw-bold" href="https://cobee.me/terms-conditions">Allgemeinen Geschäftsbedingungen (AGB)</a> gelesen habe und diese akzeptiere. 
                        {/* Um fortzufahren, bestätigen Sie bitte, dass Sie die <a className="fw-bold" href="https://cobee.me/terms-conditions">Nutzungsbedingungen</a> und <a className="fw-bold" href="https://cobee.me/privacy-policy">Datenschutzbestimmungen</a> gelesen und verstanden haben und diesen zustimmen. Bitte beachten Sie, dass Ihre fortgesetzte Nutzung unserer Dienste Ihre Zustimmung zu diesen Bedingungen voraussetzt. */}
                    </label>
                    <p className='small mb-2 opacity-50'>Ich habe von den <a className="fw-bold" href="https://cobee.me/privacy-policy">Datenschutzbestimmungen</a> Kenntnis genommen.</p>
                    <label className="form-check-label" htmlFor="acceptTermsCheckbox">
                    </label>
                </div>

                {/* Checkbox for marketing mailing opt-in */}
                {/* <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="optInMarketingCheckbox"
                        checked={optInMarketing}
                        onChange={handleOptInMarketingChange}
                    />
                    <label className="small opacity-50">
                    Ja, ich möchte gerne Neuigkeiten, Angebote und Informationen zu aktuellen Produkten und Dienstleistungen per E-Mail erhalten. Ich verstehe, dass ich diese Zustimmung jederzeit widerrufen kann, indem ich den Anweisungen in den empfangenen E-Mails folge. Weitere Informationen finden Sie in unseren <a className="fw-bold" href="https://cobee.me/privacy-policy">Datenschutzbestimmungen</a>.
                    </label>
                    <label className="form-check-label" htmlFor="optInMarketingCheckbox">
                    </label>
                </div> */}

            </div >
        );
    };

    const renderForm = () => {
        switch (step) {
            case 1:
                return (
                    <>
                        <h4 className="mb-4">Was für einen Arbeitsplatz möchtest du vermieten?</h4>
                        <p>Du kannst auch mehrere Branchen auswählen, wenn deine Räumlichkeiten für verschiedene Dienstleistungen geeignet sind.</p>
                        <CategorySelector
                            key={2}
                            selectedCategories={selectedCategories}
                            onCategoryChange={handleCategoryChange}
                        />
                    </>
                );
            case 2:
                return (
                    <>
                        <h6 className='my-4'>Möchtest Du einen Arbeitsplatz oder einen ganzen Raum vermieten?</h6>
                        <RoomSeatSelector
                            key={2}
                            selectedEntity={selectedEntity}
                            onEntityTypeChange={handleEntityTypeChange}
                        />
                        {selectedEntity === "room" && (
                            <>
                                <h6 className='mt-5'>Wieviel Quadratmeter hat der ganze Raum?</h6>

                                <div className="form-group d-flex mb-3 justify-content-center">
                                    <div className="col-sm-2 my-auto">
                                        <input
                                            id="squaremeter"
                                            type='number'
                                            className='form-control'
                                            value={squaremeter}
                                            onChange={(e) => {
                                                setSquaremeter(e.target.value);
                                                handleInputChange('squaremeter', e.target.value);
                                                setFormData((prevFormData) => ({
                                                    ...prevFormData,
                                                    squaremeter: e.target.value
                                                }));
                                            }}
                                            placeholder='3 qm'
                                        />
                                    </div>
                                    <label htmlFor="total-seat-count" className="col-sm-auto px-2 col-form-label my-auto">
                                        <div className='fw-bold'>m<sup>2</sup></div>
                                    </label>
                                </div>

                            </>
                        )}
                    </>
                );
            case 3:
                return (
                    <>
                        <h6 className='mb-3'>Erzähl uns wer Du bist.</h6>
                        <div className="row mb-3">
                            <label className="col-sm-5 col-form-label my-auto">Wie heißt dein Laden/Praxis?</label>
                            <div className="col-sm-7">
                                <input
                                    type="text"
                                    placeholder={t('title')}
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                        setFormData({ ...formData, title: e.target.value });
                                    }}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group row mb-3">
                            <label htmlFor="listing_content" className="col-sm-5 col-form-label my-auto">
                                Beschreibung
                                <small className="d-block opacity-50">Eine kurze Beschreibung hilft dir die richtigen Cobees zu finden.</small>
                            </label>

                            <div className="col-sm-7">
                                <textarea
                                    placeholder={t('Description')}
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                        setFormData({ ...formData, description: e.target.value });
                                    }}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <hr></hr>

                        {/* Address fields */}
                        <div className="row mb-3">
                            <label className="col-sm-5 col-form-label">{t('Wo ist Deine Location?')}</label>
                            <div className="col-sm-7">
                                {/* Street */}
                                <input
                                    type="text"
                                    placeholder={t('Street')}
                                    value={street}
                                    onChange={(e) => {
                                        setStreet(e.target.value);
                                        setFormData({ ...formData, street: e.target.value });
                                    }}
                                    className="form-control mb-2"
                                />
                                {/* House Number */}
                                <input
                                    type="text"
                                    placeholder={t('streetNumber')}
                                    value={houseNumber}
                                    onChange={(e) => {
                                        setHouseNumber(e.target.value);
                                        setFormData({ ...formData, houseNumber: e.target.value });
                                    }}
                                    className="form-control mb-2"
                                />
                                {/* Postal Code */}
                                <input
                                    type="text"
                                    placeholder={t('PLZ')}
                                    value={postalCode}
                                    onChange={(e) => {
                                        setPostalCode(e.target.value);
                                        setFormData({ ...formData, postalCode: e.target.value });
                                    }}
                                    className="form-control mb-2"
                                />
                                {/* City */}
                                <input
                                    type="text"
                                    placeholder={t('city')}
                                    value={city}
                                    onChange={(e) => {
                                        setCity(e.target.value);
                                        setFormData({ ...formData, city: e.target.value });
                                    }}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <hr></hr>

                        {/* Anrede (Salutation) */}
                        {/* <div className="row mb-3">
                            <label className="col-sm-5 col-form-label my-auto">{t('Salutation')}</label>
                            <div className="col-sm-7">
                                <select
                                    value={salutation}
                                    onChange={(e) => {
                                        setSalutation(e.target.value);
                                        setFormData({ ...formData, salutation: e.target.value });
                                    }}
                                    className="form-select"
                                >
                                    <option value="">{t('Salutation')}</option>
                                    <option value="Herr">{t('mr')}</option>
                                    <option value="Frau">{t('mrs')}</option>
                                </select>
                            </div>
                        </div> */}

                        {/* Firstname */}
                        <div className="row mb-3">
                            <label className="col-sm-5 col-form-label my-auto">{t('Firstname')}</label>
                            <div className="col-sm-7">
                                <input
                                    type="text"
                                    placeholder={t('Firstname')}
                                    value={firstname}
                                    onChange={(e) => {
                                        setFirstname(e.target.value);
                                        setFormData({ ...formData, firstname: e.target.value });
                                    }}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        {/* Lastname */}
                        <div className="row mb-3">
                            <label className="col-sm-5 col-form-label my-auto">{t('Lastname')}</label>
                            <div className="col-sm-7">
                                <input
                                    type="text"
                                    placeholder={t('Lastname')}
                                    value={lastname}
                                    onChange={(e) => {
                                        setLastname(e.target.value);
                                        setFormData({ ...formData, lastname: e.target.value });
                                    }}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        {/* Email */}
                        <div className="row mb-3">
                            <label className="col-sm-5 col-form-label my-auto">{t('Email')}</label>
                            <div className="col-sm-7">
                                <input
                                    type="text"
                                    placeholder={t('Email')}
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setFormData({ ...formData, email: e.target.value });
                                    }}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        {/* Telephone */}
                        <div className="row mb-3">
                            <label className="col-sm-5 col-form-label my-auto">{t('Telephone')}</label>
                            <div className="col-sm-7">
                                <input
                                    type="text"
                                    placeholder={t('Telephone')}
                                    value={telephone}
                                    onChange={(e) => {
                                        setTelephone(e.target.value);
                                        setFormData({ ...formData, telephone: e.target.value });
                                    }}
                                    className="form-control"
                                />
                            </div>
                        </div>

                    </ >
                );
            case 4:
                return (
                    <>
                        <WeekdaySelector
                            onWeekdayChange={handleWeekdayChange}
                            selectedWeekdays={selectedWeekdays}
                        />
                    </>
                );
            case 5:
                return (
                    <>
                        <h6 className="col-sm-12 col-form-label">Preisgestaltung</h6>
                        <label htmlFor="" className="h6 col-sm-12 col-form-label fw-bold">Wieviel soll der Arbeitsplatz bei einer Einzelbuchung kosten? </label>
                        <p className='text-muted'>Bitte trage hier den Preis ein. Wir errechnen dir daraus Vorschläge für die Preise im Monatsabo. Alle hier eingegeben Preise sind netto und ohne Servicegebühren.</p>
                        <p className='text-muted'>Bei erfolgreicher Vermittlung werden 2% von den Mieteinnahmen als Servicegebühr einbehalten.</p>

                        <DayRateForm
                            initialDailyRate={initialDailyRate}
                            onInitialDailyRateChange={handleInitialDailyRateChange}
                            additionalDailyRates={additionalDailyRates}
                            onAdditionalDailyRatesChange={onAdditionalDailyRatesChange}
                            additionalDailyRatesArray={additionalDailyRatesArray}
                            selectedWeekdays={selectedWeekdays}
                            onDayRatesChange={handleDayRatesChange}
                            handleInputChange={handleInputChange}
                            formData={formData} 
                            setFormData={setFormData}

                        // setInitialDailyRate={setInitialDailyRate}
                        />
                    </>
                );
            case 6:
                return (
                    <>
                        {/* <label className="col-sm-5 col-form-label">Bilder</label> */}
                        <h6 className='mb-3'>6. Zeigen Deinen Laden/Praxis</h6>
                        <div className="col-sm-12">
                            <Dropzone onDrop={handleDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()} className="dropzone">
                                        <input {...getInputProps()} className="form-control" />
                                        <p>Drag and drop some files here, or click to select files</p>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <div className='col-2'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0.5} stroke="black" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                            <div className='mt-2'>
                                {filenames.length > 0 && (
                                    <ul className="list-group mb-3">
                                        {filenames.map((filename, index) => (
                                            <li key={index} className="list-group-item">
                                                <div className='row'>
                                                    <div className='col-11 my-auto'>{filename}</div>
                                                    <div className='col-1'>
                                                        <svg onClick={() => deletePicture(index)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {previewURLs.length > 0 && (
                                    <div className="row">
                                        {previewURLs.map((url, index) => (
                                            <div key={index} className="col-md-2">
                                                <div className='col-4'>
                                                    <svg onClick={() => deletePicture(index)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </div>
                                                <img src={url} alt="Uploaded" className="img-fluid rounded" />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div >
                    </>
                );
            case 7:
                return (
                    <>
                        <h5 className="mb-4">7. Ausstattung</h5>
                        <hr></hr>

                        {renderAdditionalOptions()}
                        <hr></hr>

                        {renderAdditionalOptionsII()}
                        <hr></hr>

                        {renderCobeeResources()}
                    </>
                );
            case 8:
                return gatherInputValues();

            default:
                return null;
        }
    };

    useEffect(() => {

        setFormData(prevState => ({
            ...prevState,
            rateDay: initialDailyRate, // Stellen Sie sicher, dass initialDailyRate definiert und korrekt berechnet ist
        }));
        
        // setSelectedWeekdays([]);
        // Hier können Sie die Daten abrufen, die Sie bereits in formData gesetzt haben
        // Zum Beispiel könnten Sie einen API-Aufruf machen, um die Daten abzurufen
        // Oder Sie könnten die Daten aus dem lokalen Speicher oder aus einem globalen Zustand abrufen
    }, [selectedEntity, formData, formData.selectedCategories]);

    return (
        <div style={{ minHeight: '100vh', backgroundImage: 'url(../cobee-halftone-green.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="container-lg">
                <div className="row justify-content-center">
                    <div className="col-lg-8 p-0">
                        <div className="px-lg-5 mb-5 py-4 my-5 rounded-4 bg-white " >
                            <form onSubmit={(event) => handleSubmit(event)} className="px-4 my-5">
                                {/* <h4 class="my-2">#Arbeitsplatz anbieten</h4> */}
                                {/* <div htmlFor="" className="h6 col-sm-5 mb-2">Was können die Kunden des Cobees erwarten?</div> */}
                                {/* <hr /> */}
                                {renderForm()}
                                <div className="full-width-element d-flex justify-content-end bg-white border-top py-4 fixed-bottom">
                                    {/* {step === 1 && (
                                    <button type="button" class="btn btn-lg" data-bs-dismiss="modal" aria-label="Close">Zurück</button>
                                )} */}
                                    {step > 1 && (
                                        <button type="button" className="btn btn-lg" onClick={prevStep}>
                                            {t('Previous')}
                                        </button>
                                    )}
                                    {step < 8 && (
                                        <button type="button" className="btn btn-lg btn-dark ms-3" onClick={nextStep}>
                                            {t('Next')}
                                        </button>
                                    )}
                                    {step === 8 && (
                                        <button 
                                            type="submit" 
                                            className="btn btn-lg btn-primary"
                                            disabled={!acceptTerms}
                                            onClick={handleSubmit}>
                                            {t('Submit')}
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEntity;
