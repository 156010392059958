import React, { useState, useRef, useEffect } from 'react';
// import { Calendar, utils } from "react-modern-calendar-datepicker";
// import 'react-modern-calendar-datepicker/lib/DatePicker.css';
// import Calender from '@hassanmojab/react-modern-calendar-datepicker';
// import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import numeral from 'numeral';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import myDatePickerLocale from './DatePickerLocale'
import BookingWeek from './BookingWeek';
import './BookingWeek.css';
import { useTranslation } from 'react-i18next';
import BookingFormModal from './BookingFormModal';
import Swal from 'sweetalert2';
import WeekdaySelector from './WeekdaysSelector';


const BookingForm = ({ step, handlePreviousStep, handleFormSubmit, bookTimeslots, entity }) => {

	const { t } = useTranslation();

	const [entityState, setEntityState] = useState(entity);
	console.log("Entity ID: ", entityState?._id);
	const entityId = entityState?._id;
	console.log(entityId);
	const [selectedDay, setSelectedDay] = useState(null);
	const [selectedOption, setSelectedOption] = useState('');
	const [bookingType, setBookingType] = useState('subscription'); // For booking type
	const [bookingDuration, setBookingDuration] = useState(''); // For booking duration

	const handleBookingTypeChange = (event) => {
		setBookingType(event.target.value);

		if (event.target.value === 'subscription') {
			const displaySubscriptionType = document.getElementById('display-subscription-type');
			if (displaySubscriptionType) {
				displaySubscriptionType.innerText = 'monatlich ';
			}
		} else {
			const displaySubscriptionType = document.getElementById('display-subscription-type');
			if (displaySubscriptionType) {
				displaySubscriptionType.innerText = '';
			} 
		}
	};

	const handleBookingDurationChange = (event) => {
		setBookingDuration(event.target.value);
	};

	const handleOptionChange = (event) => {
		// setSelectedOption(event.target.value);
		setFormData((prevFormData) => ({
			...prevFormData,
			bookingDuration: event.target.value // Update the bookingDuration property
		}));
	};

	const [formData, setFormData] = useState({
		Entities: entityState?._id,
		status: 'pending',
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		acceptedTerms: false,
		date: null,
		bookingDuration: selectedOption
	});

	const handleInputChange = (event) => {
		const { name, value, type, checked } = event.target;
		const fieldValue = type === 'checkbox' ? checked : value;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: fieldValue
		}));
	};

	const handleDateChange = (selectedDate) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			date: selectedDate
		}));
	};

	// const handleFormSubmit = () => {
	// 	handleSubmit(formData);
	// };

	const handleSubmit = async (event) => {
		event.preventDefault();

		try {
			const response = await fetch(process.env.REACT_APP_BASE_URL_API + '/addbooking', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData)
			});

			if (response.ok) {
				// Handle successful booking submission
				setFormData(formData);
				console.log('Send successfully');
				// Show success SweetAlert
				Swal.fire({
					icon: 'success',
					title: 'Buchungsanfrage Verschickt',
					text: 'Deine Anfragen ist eingegangen. Du bekommst in kürze von uns bescheid.',
					confirmButtonText: 'OK'
				}).then(() => {
					// Perform any additional actions after the user clicks OK
					// For example, you can navigate to a different page or reset the form
					// handleFormSubmit(formData);
					// Refresh the current page
					window.location.reload();
				});
			} else {
				// Handle error response
				console.error('Error submitting booking');
				// Show error SweetAlert
				Swal.fire({
					icon: 'error',
					title: 'Booking Error',
					text: 'An error occurred while submitting your booking. Please try again.',
					confirmButtonText: 'OK'
				});
			}
		} catch (error) {
			// Handle network or other errors
			console.error('Error submitting booking:', error);
			// Show error SweetAlert
			Swal.fire({
				icon: 'error',
				title: 'Booking Error',
				text: 'An error occurred while submitting your booking. Please try again.',
				confirmButtonText: 'OK'
			});
		}
	};

	const [selectedWeekdays, setSelectedWeekdays] = useState([]);
	const allWeekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
	// const availableWeekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']; // Example available weekdays
	console.log(entity.selectedWeekdays);
	const availableWeekdays = entity.selectedWeekdays || [];

	const handleWeekdayChange = (event) => {
		const clickedDay = event.target.value;
		setSelectedWeekdays(prevSelectedWeekdays =>
			prevSelectedWeekdays.includes(clickedDay)
				? prevSelectedWeekdays.filter(day => day !== clickedDay)
				: [...prevSelectedWeekdays, clickedDay]
		);
	};

	const renderWeekDaysSelector = () => {
		const { dailyPrice, monthlyPrice } = calculatePrices();
		return (
			<div id="weekDaysSelector" className="mb-3">
				<hr></hr>
				<h5 className="my-0">Wochentage</h5>
				<label className="d-block opacity-50 me-3 mt-2 mb-3 small w-100">
					Wähle die Tage an denen Du den Cobbe Space buchen möchtest.
				</label>
				<div className="w-100 d-flex flex-wrap">
					{allWeekdays.map((day) => (
						<div key={day} className={`btn-group d-block d-md-inline mb-2 me-2 ${isMobileScreen() ? "w-100" : ""}`}>
							<input
								type="checkbox"
								id={day}
								name="weekday"
								onChange={handleWeekdayChange}
								className="form-check-input visually-hidden"
								value={day}
								checked={selectedWeekdays.includes(day)}
								disabled={!availableWeekdays.includes(day)}
							/>
							<label
								htmlFor={day}
								className={`d-block text-center form-check-label rounded-pill border me-0 px-3 py-2 ${!availableWeekdays.includes(day)
									? "text-muted bg-gray"
									: selectedWeekdays.includes(day)
										? "border-dark bg-dark text-white"
										: "border-dark"
									} ${isMobileScreen() ? "w-100" : ""}`} // Add w-100 class for mobile screens
							>
								{t(day)}
							</label>
						</div>
					))}
				</div>
				<div>
					<p>Durschnittlicher Tagespreis für <strong className='fw-bold'>{selectedWeekdays.length * 4} Tage im Monat</strong>: <strong className='fw-bold'>{dailyPrice} €</strong> / Tag</p>
				</div>
			</div>
		);
	};

	const isMobileScreen = () => {
		return window.innerWidth <= 767; // Adjust the breakpoint as needed
	};

	// Define dayRates array
	const dayRates = Array.from({ length: 7 }, (_, i) => (entity.rateDay * 1.06) * Math.pow(0.95, i) );

	// Calculate daily and monthly prices
	const calculatePrices = () => {
		let dailyPrice = 0;
		let monthlyPrice = 0;
		const selectedDaysCount = selectedWeekdays.length;
		if (bookingType === "single") {
			dailyPrice = Math.round(dayRates[0]);
			return { dailyPrice };
		} else if (bookingType === "subscription") {
			dailyPrice = Math.round(dayRates[selectedDaysCount - 1]);
		}

		monthlyPrice = Math.round(dailyPrice * selectedWeekdays.length * 4); // Multiply by 4 to get the monthly price

		return { dailyPrice: dailyPrice || 0, monthlyPrice: monthlyPrice || 0 };
	};

	useEffect(() => {
		const { dailyPrice, monthlyPrice } = calculatePrices();
		const monthlyTotalElement = document.getElementById('monthlyTotal');
		if (monthlyTotalElement) {
		  const formattedDailyPrice = numeral(dailyPrice).format('0,0');
		  const formattedMonthlyPrice = numeral(monthlyPrice).format('0,0');
		  if (bookingType === "single") {
			monthlyTotalElement.textContent = `${formattedDailyPrice} €`;
		  } else {
			monthlyTotalElement.textContent = `${formattedMonthlyPrice} €`;
		  }
		}
	  }, [bookingType, selectedWeekdays]);

	const renderForm = () => {

		switch (step) {
			case 1:
				return (
					<div className="step-1 row justify-content-center">
						<div className='col-md-10'>
							<h4>Buchungsart</h4>
							<p>Möchtest du deine Cobee Space einmalig oder im Abo buchen?</p>
							<hr></hr>
							<div id="subscription-select" className='my-4'>
								<div className="mb-3">
									<h4 className="form-label me-3 d-block">Buchungsart</h4>
									<div className="btn-group" role="group" aria-label="Booking Type">
										<input
											type="radio"
											id="subscription"
											name="bookingType"
											value="subscription"
											checked={bookingType === "subscription"}
											onChange={handleBookingTypeChange}
											className="btn-check"
										/>
										<label htmlFor="subscription" className="btn btn-outline-dark ">
											Monats-Abo
										</label>
										<input
											type="radio"
											id="singleBooking"
											name="bookingType"
											value="single"
											checked={bookingType === "single"}
											onChange={handleBookingTypeChange}
											className="btn-check"
										/>
										<label htmlFor="singleBooking" className="btn btn-outline-dark ">
											Einzelbuchung
										</label>
									</div>
									<label className="d-block opacity-50 me-3 mt-2 small w-100">Wähle die Art der Buchung.</label>
								</div>
							</div>
							{/* Conditionally render WeekDaysSelector based on selectedOption */}
							{/* {renderWeekDaysSelector()} */}
							{bookingType === 'subscription' && renderWeekDaysSelector()}
							<div id="bookingrange-select" className="my-3 d-none">
								{/* <label className="form-label me-3">Zeitraum</label> */}
								<h4 className="form-label me-3 d-block">Zeitraum</h4>
								<div className="btn-group" role="group" aria-label="Booking Duration">
									<input
										type="radio"
										id="option1"
										name="bookingDuration"
										value="ongoing"
										checked={bookingDuration === "ongoing"}
										onChange={handleBookingDurationChange}
										className="btn-check"
									/>
									<label htmlFor="option1" className="btn btn-outline-dark ">
										Fortlaufend
									</label>

									<input
										type="radio"
										id="option2"
										name="bookingDuration"
										value="threeMonths"
										checked={bookingDuration === "threeMonths"}
										onChange={handleBookingDurationChange}
										className="btn-check"
									/>
									<label htmlFor="option2" className="btn btn-outline-dark ">
										3 Monate
									</label>

									<input
										type="radio"
										id="option3"
										name="bookingDuration"
										value="fourMonths"
										checked={bookingDuration === "fourMonths"}
										onChange={handleBookingDurationChange}
										className="btn-check"
									/>
									<label htmlFor="option3" className="btn btn-outline-dark ">
										1 Monat
									</label>
								</div>
								<label className="d-block opacity-50 me-3 mt-2 small">Wähle die Dauer in dem du dieses Angebot buchen möchtest.</label>
							</div>
							{/* <div><BookingWeek /></div> */}
						</div>
					</div>
				);
			case 2:
				return (
					<div className="step-1 row justify-content-center">
					<div className='col-md-10'>
						<h4>Mietbeginn</h4>
						<p>Trage den ersten Tag ein an dem du mit deiner Miete starten möchtest.</p>
						<div className="mb-3 d-flex justify-content-center">
							{/* <label htmlFor="date" className="form-label">Booking Start Date:</label> */}
							<Calendar
								value={formData.date}
								onChange={handleDateChange}
								minimumDate={utils().getToday()}
								inputClassName="form-control"
								locale={myDatePickerLocale} // add this
								required
								/>
						</div>
					</div>
					</div>
				);
			case 3:
				return (
					<div className="step-2 row justify-content-center">
						<div className='col-md-10'>
							{/* <h5>2<span className="opacity-25">|3</span> Deine Daten */}
							<h5>Deine Daten</h5>
							<p>
								Stelle deine Buchungsanfrage und arbeite schon in kürze in den Räumen von { }
							</p>
							<hr></hr>
							<div className="row mb-3">
								<label htmlFor="firstName" className="col-sm-5 col-form-label my-auto">Vorname</label>
								<div className="col-sm-7">	
									<input
										type="text"
										id="firstName"
										name="firstName"
										placeholder="Vorname"
										className="form-control"
										value={formData.firstName}
										onChange={handleInputChange}
										required
									/>
								</div>
							</div>
							<div className="row mb-3">
								<label htmlFor="lastName" className="col-sm-5 col-form-label my-auto">Nachname</label>
								<div className="col-sm-7">
									<input
										type="text"
										id="lastName"
										name="lastName"
										placeholder="Nachname"
										className="form-control"
										value={formData.lastName}
										onChange={handleInputChange}
										required
									/>
								</div>
							</div>
							<div className="row mb-3">
								<label htmlFor="email" className="col-sm-5 col-form-label my-auto">Email</label>
								<div className="col-sm-7">
									<input
										type="email"
										id="email"
										name="email"
										placeholder="youremail@example.com"
										className="form-control"
										value={formData.email}
										onChange={handleInputChange}
										required
									/>
								</div>
							</div>
							<div className="row mb-3">
								<label htmlFor="phone" className="col-sm-5 col-form-label my-auto">Telefon</label>
								<div className="col-sm-7">
									<input
										type="text"
										id="phone"
										name="phone"
										placeholder="+49 000 000"
										className="form-control"
										value={formData.phone}
										onChange={handleInputChange}
										required
									/>
								</div>
							</div>
							<hr></hr>
							
						</div>
					</div>
				);
			case 4:
				return (
					<div className="step-3 row justify-content-center">
						<div className='col-md-10'>
							<h5>3<span className="opacity-25">|3</span> Zusammenfassung</h5>
							{/* <h5>Zusammenfassung</h5> */}
							<hr></hr>
							<label className='small opacity-50'>Name</label>
							<p>{formData.firstName} {formData.lastName}</p>
							<label className='small opacity-50'>Email</label>
							<p>{formData.email}</p>
							<label className='small opacity-50'>Telefon</label>
							<p>{formData.phone}</p>
							<label className='small opacity-50'>Beginn</label>
							<p>{formData.date ? `${formData.date.day}.${formData.date.month}.${formData.date.year}` : 'No date selected'}</p>
							{/* <label className='small opacity-50'>Dauer</label>
							<p>{t(`bookingDuration.${formData.bookingDuration}`)}</p> */}
							{/* <p>ENTITY ID: {t(`${formData.Entities}`)}</p> */}
							<div className="mb-3 form-check">
								<input
									type="checkbox"
									id="acceptTerms"
									name="acceptedTerms"
									className="form-check-input"
									checked={formData.acceptedTerms}
									onChange={handleInputChange}
									required
								/>
								<label htmlFor="acceptTerms" className="small opacity-75">
									Hiermiet akzeptiere ich die <a className="fw-bold" href="/terms-and-conditions">AGB's und Datenschautzbestimmungen.</a>
								</label>
							</div>
							<button
								id="booking-footer-button"
								type="button"
								className={`btn btn-lg btn-primary ${!formData.acceptedTerms ? 'disabled' : ''}`}
								onClick={handleSubmit}
								disabled={!formData.acceptedTerms}
							>
								Unverbindliche Buchungsanfrage
							</button>
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<>
			<form id="booking-form" onSubmit={handleSubmit}>
				{renderForm()}
				<style jsx>{`
				.Calendar {
					--cl-color-black: #444444;
					--cl-color-disabled: #d4d4d4;
					--cl-color-error: #ff2929;
					font-size: 10px;
					background: #fff;
					box-shadow: 0 1em 4em rgba(0, 0, 0, 0.00);
					
					.Calendar__day.-selected, .Calendar__day.-selectedStart, .Calendar__day.-selectedEnd {
						background: var(--cobee-background-dark);
						color: #fff;
					}
					
					.Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText, .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
						background: var(--cobee-background-dark);
						color: #fff;
					}
					`}
				</style>
			</form>
		</>
	);

};

export default BookingForm;
