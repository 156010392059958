import React from 'react';

const TermsConditions: React.FC = () => {
    return (
        <div className='container mt-5'>
            <div className="fw-bold fs-2">Allgemeine Geschäftsbedingungen</div>
            <div className="fs-5 fw-bold">1. Geltungsbereich</div>
            <p>1.1 Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") regeln die Nutzung der Plattform Cobee.me (nachfolgend "Cobee") durch Vermieter und Mieter im Bereich der Vermittlung von Arbeitsplätzen und ganzen Arbeitsräumen für körpernahe Dienstleistungen und Heilberufe.</p>

            <div className="fw-bold fs-5 mb-2">2. Nutzungsbedingungen</div>
            <p>2.1 Die Nutzung von Cobee steht unbeschränkt volljährigen natürlichen Personen, juristischen Personen und Einzelgesellschaften (Freelancern) offen.</p>
            <p>2.2 Cobee behält sich das Recht vor, diese AGB jederzeit anzupassen. Änderungen werden den Vermietern und Mietern im Voraus mitgeteilt.</p>

            <div className="fw-bold fs-5 mb-2">3. Leistungsbeschreibung</div>
            <p>3.1 Cobee vermittelt Arbeitsplätze und -räume für körpernahe Dienstleistungen und Heilberufe. Cobee agiert dabei ausschließlich als Vermittler und erhält für seine Serviceleistungen eine Servicegebühr.</p>
            <p>3.2 Die Service von Cobee umfasst die Bereitstellung der Plattform für die Präsentation des Untermietangebots, die Vermittlung an potentielle Mieter und bei Zustandekommen eines Mietvertrages (Tagesmiete oder Monatsabo) die Zahlungsabwicklung zwischen Vermieter und Mieter.</p>

            <div className="fw-bold fs-5 mb-2">4. Vertragsabschluss</div>
            <p>4.1 Vermieter können kostenlos Inserate auf der Cobee Plattform einstellen. Neben Bildern und Beschreibung des Mietobjektes muss der Vermieter auch Tagespreise und Monatspreise für die verschiedenen Mietformen angeben. Stellt ein potentieller Mieter eine Buchungsanfrage, so wird diese an den Vermieter weitergeleitet. Der Vermieter hat nun 2 Werktage Zeit, die Buchungsanfrage anzunehmen oder abzulehnen. Eine Annahme der Buchungsanfrage durch den Vermieter ist bindend, sofern der potentielle Mieter innerhalb 2 Werktagen die Buchung bestätigt. Mit der Bestätigung der Buchung kommt ein rechtlich bindender Vertrag zwischen Mieter und Vermieter zustande.</p>

            <div className="fw-bold fs-5 mb-2">5. Pflichten der Vermieter</div>
            <p>5.1 Die Servicegebühr für Vermieter beträgt 2% der Mietbeträge und wird von der Mietgutschrift abgezogen.</p>
            <p>5.2 Vermieter sind für die Inhalte ihrer Inserate selbst verantwortlich. Cobee überprüft die Inserate nicht auf Qualität und Rechtmäßigkeit.</p>
            <p>5.3 Der Vermieter ist verpflichtet, sein Inserat aktuell zu halten, insbesondere im Hinblick auf Verfügbarkeit, Preise und Kontaktinformationen. Änderungen an Inseraten können vom Vermieter an service@cobee.me gesendet werden.</p>
            <p>5.4 Vermieter bestätigen, dass sie berechtigt sind, das Mietobjekt unterzuvermieten.</p>
            <p>5.5 Die Annahme einer Buchungsanfrage durch den Vermieter ist für den Vermieter 2 Werktage bindend.</p>
            <p>5.6 Bei ausbleibenden Mietzahlungen durch den Mieter hat der Vermieter das Recht auf außerordentliche, unverzügliche Kündigung.</p>

            <div className="fw-bold fs-5 mb-2">6. Mieter</div>
            <p>6.1 Die Servicegebühr für Mieter beträgt 6% der fälligen Mieten.</p>
            <p>6.2 Der Mieter hat die Möglichkeit, Tagesmieten oder Monatsmieten anzufragen. Monatsmieten sind fortlaufende Mietverträge, die sich monatlich verlängern, solange der Mietvertrag von keiner Seite gekündigt wurde. Es gelten die Kündigungsbestimmungen.</p>
            <p>6.3 Mieten (Tagesmieten und Monatsmieten) sind jeweils bis 3 Werktage vor Mietbeginn zu zahlen. Andernfalls kann der Zutritt verweigert werden. Bei fortlaufenden Monatsmieten jeweils 3 Werktage vor dem neuen Monat.</p>
            <p>6.4 Cobee behält sich vor, marktübliche Mahngebühren bei einem Verzug von mehr als 5 Werktagen zu erheben.</p>
            <p>6.5 Monatsabos können vom Mieter mit einer Frist von einem Monat zum Monatsende gekündigt werden.</p>
            <p>6.6 Tagesbuchungen können nicht gekündigt oder storniert werden. Kulanzanfragen richten Sie bitte an service@cobee.me.</p>

            <div className="fw-bold fs-5 mb-2">7. Mietbetrag, Servicegebühren und Steuern</div>
            <p>7.1 Ob der Mietbetrag und die Servicegebühr mit oder ohne Umsatzsteuer berechnet wird, ist abhängig von verschiedenen Faktoren. Die genauen Modalitäten werden vor der verbindlichen Buchungsanfrage mitgeteilt.</p>
            <p>7.2 Der Mietbetrag wird vom Vermieter festgelegt und gilt für die Dauer des Mietvertrages.</p>

            <div className="fw-bold fs-5 mb-2">8. Stornobedingungen</div>
            <p>8.1 Einzelbuchungen (Tagesmiete) können bis 14 Werktage vor Mietbeginn kostenlos storniert werden.</p>
            <p>8.2 Monatsabos können nicht storniert werden.</p>

            <div className="fw-bold fs-5 mb-2">9. Kündigung</div>
            <p>9.1 Tagesmieten bedürfen keiner Kündigung, da sie einmalige Buchungen für einen Tag darstellen.</p>
            <p>9.2 Monatsabos können von Vermieter und Mieter mit einer Frist von einem Monat zum Monatsende gekündigt werden.</p>

            <div className="fw-bold fs-5 mb-2">10. Zahlungsmodalitäten</div>
            <p>10.1 Die Zahlung des Mietbetrags inkl. der Servicegebühr durch den Mieter hat bis spätestens 3 Werktage vor Mietbeginn zu erfolgen. Bei verspäteter Zahlung kann der Zutritt zum Mietobjekt verweigert werden.</p>
            <p>10.2 Die Mietbeträge werden dem Mieter inkl. 3 Werktage nach Eingang bei Cobee an das von ihm angegebene, deutsche Konto abzüglich der Servicegebühr überwiesen. Eine Auszahlung in Bar ist nicht möglich.</p>

            <div className="fw-bold fs-5 mb-2">11. Gerichtsstand</div>
            <p>11.1 Gerichtsstand für Streitigkeiten aus oder im Zusammenhang mit diesen AGB ist Berlin.</p>

            <div className="fw-bold fs-5 mb-2">12. Haftungsklausel und Haftungsausschluss</div>
            <p>12.1 Cobee haftet nicht für entstandene Schäden, die durch Mieter verursacht werden. Die Haftung von Cobee ist auf grobe Fahrlässigkeit und Vorsatz beschränkt.</p>

            <div className="fw-bold fs-5 mb-2">13. Haftungsausschluss bis auf grobe Fahrlässigkeit</div>
            <p>13.1 Cobee schließt jegliche Haftung, soweit gesetzlich zulässig, bis auf grobe Fahrlässigkeit aus. Cobee übernimmt keine Gewähr für die Qualität der vermittelten Räumlichkeiten oder die Erfüllung der zwischen Vermieter und Mieter geschlossenen Verträge.</p>

            <div className="fw-bold fs-5 mb-2">14. Haftung des Vermittlers</div>
            <p>14.1 Cobee haftet nicht für Schäden, die direkt aus der Vermittlungstätigkeit resultieren. Jegliche Haftung von Cobee ist auf grobe Fahrlässigkeit und Vorsatz beschränkt.</p>

            <div className="fw-bold fs-5 mb-2">15. Salvatorische Klausel</div>
            <p>15.1 Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden, so bleibt die Wirksamkeit der übrigen Bestimmungen hiervon unberührt. Unwirksame Bestimmungen gelten als durch solche ersetzt, die dem wirtschaftlichen Sinn und Zweck der unwirksamen Bestimmungen in rechtswirksamer Weise am nächsten kommen.</p>

            <small className='opacity-50'>Version: 1.1 vom 12.01.2024</small>

        </div>
    );
};

export default TermsConditions;
