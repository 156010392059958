const myDatePickerLocale = {
  // months list by order
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],

  // week days by order
  weekDays: [
    {
      name: 'Montag',
      short: 'Mo.',
    },
    {
      name: 'Dienstag',
      short: 'Di.',
    },
    {
      name: 'Mittwoch',
      short: 'Mi.',
    },
    {
      name: 'Donnerstag',
      short: 'Do.',
    },
    {
      name: 'Freitag',
      short: 'Fr.',
    },
    {
      name: 'Samstag',
      short: 'Sa.',
      isWeekend: true,
    },
    {
      name: 'Sonntag', // used for accessibility 
      short: 'So.', // displayed at the top of days' rows
      isWeekend: true, // is it a formal weekend or not?
    },
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 6,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit;
  },

  // texts in the date picker
  nextMonth: 'Nächster Monat',
  previousMonth: 'Vorheriger Monat',
  openMonthSelector: 'Monatsauswahl öffnen',
  openYearSelector: 'Jahresauswahl öffnen',
  closeMonthSelector: 'Monatsauswahl schließen',
  closeYearSelector: 'Jahresauswahl schließen',
  defaultPlaceholder: 'Beginn Auswählen...',


  // for input range value
  from: 'Von',
  to: 'bis',


  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
}

export default myDatePickerLocale;