import React from 'react';

const MietereInformation = () => {
  return (
    <div className="mt-4">
      <div className="px-4 py-5 my-5 text-center">
        {/* <img className="d-block mx-auto mb-4" src="/docs/5.3/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57"/> */}
        <h1 className="display-5 fw-bold text-body-emphasis">Deinen Traum-Arbeitsplatz</h1>
        <h5 className="display-5 fw-bold text-body-emphasis opacity-50">Egal ob Kosmetik, Yoga oder Therapie – Entdecke die perfekte Arbeitsumgebung für dein Business.</h5>
        <div className="col-lg-6 mx-auto">
          {/* <p className="lead mb-4">Egal ob Kosmetik, Yoga oder Therapie – Entdecke die perfekte Arbeitsumgebung für dein Business.</p> */}
          <p className="lead mb-4">Willkommen bei Cobee.me – deiner Plattform für die stressfreie Suche nach dem perfekten Arbeitsplatz! Egal, ob du im Kosmetikbereich, Wellness, Therapie oder einem anderen körpernahen Dienstleistungsfeld tätig bist, wir haben den idealen Raum für dich.</p>
          {/* <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <button type="button" className="btn btn-primary btn-lg px-4 gap-3">Primary button</button>
            <button type="button" className="btn btn-outline-secondary btn-lg px-4">Secondary</button>
          </div> */}
        </div>
      </div>
      <div className='p-5' style={{
				backgroundImage: "url('../bg-halftone-swing.png')",
				// backgroundImage: "url('../bg-halftone-swing.png')",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundPosition: "center"
			}}>
      <h2 className="pb-2 text-white text-center">Warum Cobee.me die perfekte Wahl für Mieter ist</h2>  
      </div>
      
      <div className="container px-4 py-5">
      {/* <h2 className="pb-2 border-bottom">Warum Cobee für deine Vermietung?</h2> */}

        <div className="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-5">
          <div className="col d-flex flex-column align-items-start gap-2">
            <h2 className="fw-bold text-body-emphasis">Vielfältige Arbeitsumgebungen</h2>
            <p className="text-body-secondary">Entdecke eine breite Palette von inspirierenden Arbeitsorten, sei es in einem Kosmetikstudio, einem Yoga-Studio, einem Friseursalon oder einer Physiotherapie-Praxis. Wähle den Ort, der perfekt zu deinem Business passt.</p>
            {/* <a href="#" className="btn btn-primary btn-lg">Primary button</a> */}
          </div>

          <div className="col">
            <div className="row row-cols-1 row-cols-sm-2 g-4">
              <div className="col d-flex flex-column gap-2">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-secondary bg-brand bg-gradient fs-4 rounded-3">
                  <svg className="bi" width="1em" height="1em">
                  </svg>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">Inspirierende Gemeinschaften</h4>
                <p className="text-body-secondary">Trete einer einzigartigen Gemeinschaft bei! Dein Arbeitsplatz ist nicht nur ein Raum – er ist Teil eines kreativen Kollektivs. Arbeite Seite an Seite mit gleichgesinnten Fachleuten, tausche Ideen aus und erweitere dein Netzwerk.</p>
              </div>

              <div className="col d-flex flex-column gap-2">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-secondary bg-brand bg-gradient fs-4 rounded-3">
                  <svg className="bi" width="1em" height="1em">
                  </svg>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">Flexible Mietoptionen</h4>
                <p className="text-body-secondary">Entscheide über die Dauer deiner Mietzeit bei Cobee.me. Egal, ob du nur kurzfristig einen Raum benötigst oder langfristig planst – wir bieten flexible Optionen, um deinen Bedürfnissen gerecht zu werden.</p>
              </div>

              <div className="col d-flex flex-column gap-2">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-secondary bg-brand bg-gradient fs-4 rounded-3">
                  <svg className="bi" width="1em" height="1em">
                  </svg>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">Transparente Kostenstruktur</h4>
                <p className="text-body-secondary">Die Servicegebühr beträgt  6% auf alle Mietn. Keine versteckten Kosten, keine Überraschungen – nur klare Konditionen für eine erfolgreiche Zusammenarbeit.</p>
              </div>

              <div className="col d-flex flex-column gap-2">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-secondary bg-brand bg-gradient fs-4 rounded-3">
                  <svg className="bi" width="1em" height="1em">
                  </svg>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">Professionelle Abwicklung</h4>
                <p className="text-body-secondary">Cobee.me kümmert sich um alle administrativen Angelegenheiten. Von Vertragsabschlüssen über Zahlungsabwicklungen bis hin zu Kündigungen – wir stehen dir zur Seite, damit du dich auf dein Business konzentrieren kannst.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='p-5' style={{
				backgroundImage: "url('../bg-halftone-swing.png')",
				// backgroundImage: "url('../bg-halftone-swing.png')",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundPosition: "center"
			}}>
      {/* <h2 className="pb-2 text-white text-center">Wie Cobee.me dein Business stärkt</h2>   */}
      </div>

      <div className="container px-4 py-5" id="featured-3">
    {/* <h2 className="pb-2 border-bottom">Wie Cobee.me dein Business stärkt</h2> */}
    {/* <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
      <div className="feature col">
        <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-secondary bg-brand bg-gradient fs-2 mb-3">+</div>
        <h3 className="fs-2 text-body-emphasis">Effiziente Zahlungsabwicklung</h3>
        <p>Stressfrei pünktliche Mietzahlungen erhalten und dich auf dein Geschäft konzentrieren.</p>
      </div>
      <div className="feature col">
        <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-secondary bg-brand bg-gradient fs-2 mb-3">+</div>
        <h3 className="fs-2 text-body-emphasis">Kein administrativer Aufwand</h3>
        <p>Cobee übernimmt die Bürokratie – von Vertragsangelegenheiten bis zur Abwicklung von Kündigungen.</p>
      </div>
      <div className="feature col">
        <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-secondary bg-brand bg-gradient fs-2 mb-3">+
        </div>
        <h3 className="fs-2 text-body-emphasis">Jetzt eintragen</h3>
        <p>Jetzt Arbeitsplatz anbieten und die Vorteile der stressfreien Vermietung nutzen.</p>
      </div>
    </div> */}
  </div>
  <div className="container my-5">
  <div className="p-5 text-center rounded-3">
    <h4 className="text-body-emphasis">Entdecke die Leichtigkeit der Arbeitsplatzsuche mit Cobee.me</h4>
    <p className="col-lg-8 mx-auto fs-5 text-muted">
    Durchstöbere die besten Angebote und finde den perfekten Arbeitsplatz für dein Business. Finde stressfrei, arbeite effizient – mit Cobee.me, deinem Partner für erfolgreiche Arbeitsplatzsuchen!
    </p>
    <div className="d-inline-flex gap-2 mb-5">
      <a href="/" className="d-inline-flex align-items-center btn btn-primary btn-lg px-4 rounded-pill" type="button">
        Jetzt Arbeitsplatz mieten
      </a>
      {/* <button className="btn btn-outline-secondary btn-lg px-4 rounded-pill" type="button"> */}
        {/* Kontakt */}
      {/* </button> */}
    </div>
  </div>
</div>
    </div>
  );
};

export default MietereInformation;
