import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import queryString from 'query-string';
import { MyContext } from './Context';
import { SearchContext } from './SearchContext';
import Cookies from 'js-cookie';
import { Search, Sliders, GeoAltFill } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.css';
import { Modal } from 'bootstrap';
import RoomSeatSelector from './RoomSeatSelector'
import Timeslots from '../components/Timeslots'
import WeekdaySelector from '../components/WeekdaysSelector';
import TimeSelector from '../components/TimeSelector';
// import CityAutosuggest from '../components/CityAutosuggest';
import GooglePlaces from '../components/GooglePlaces';


const SearchForm = () => {
    const navigate = useNavigate(); // Access the navigate function
    const { searchValues, setSearchValues } = useContext(SearchContext);
    const { state, setState } = useContext(MyContext);
    const [isLoading, setIsLoading] = useState(false);

    const [city, setCity] = useState('');
    // const [district, setDistrict] = useState('');
    const [branch, setBranch] = useState('');
    const [branchLabel, setBranchLabel] = useState('');

    const [searchResult, setSearchResult] = useState([]);
    const location = useLocation();

    const handleModalOpen = () => {
        const modalElement = document.getElementById('searchModal');
        const modal = new Modal(modalElement);
        modal.show();
    };

    const handleCityChange = (newCity) => {
        // const selectedCity = city.target.value;
        // setCity(city.target.value);
        console.log("Die Stadt wurde geändert:", newCity);
        setCity(newCity);
        // setDistrict(''); // Reset district when city changes
        console.log("Die Stadt wurde geändert:", city);
    };

    // const handleDistrictChange = (event) => {
    //     setDistrict(event.target.value);
    // };

    const handleBranchChange = (event) => {
        const selectedBranch = event.target.value;
        const selectedOption = branchOptions.find((option) => option.value === selectedBranch);
        if (selectedOption) {
            setBranchLabel(selectedOption.label);
        }
        setBranch(selectedBranch);
    };

    const handleSubmit = (event) => {

        event.preventDefault();
        console.log("City for handleSubmit: ", city);
        let url = `${process.env.REACT_APP_BASE_URL_API}/search?city=${encodeURIComponent(city)}&branch=${encodeURIComponent(branch)}`;

        console.log("City on handleSubmit: ", city)

        // setSearchResult('Performing search...');
        // const currentCity = city;  // Or use a parameter or another way to get the current city value
        // const city = 'Hamburg';  // Or use a parameter or another way to get the current city value

        // let url = process.env.REACT_APP_BASE_URL_API + `/search?city=${city}&district=${district}&branch=${branch}`;
        // let url = process.env.REACT_APP_BASE_URL_API + `/search?city=${city}&branch=${branch}`;

        console.log('Fetching search results:', url); // Debugging: Log the URL
        // Perform the search and update searchResult
        // Set isLoading to true before the search starts, and false when the search is complete

        setIsLoading(true);
        setSearchResult([]);

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                console.log('Search results received:', data);
                console.log('Updated search results:', Array.isArray(data) ? data : []);
                setTimeout(() => {
                    setSearchResult(Array.isArray(data) ? data : []);
                    setState(Array.isArray(data) ? data : []);
                    setIsLoading(false);
                    //navigate('/'); // Redirect to the home page
                }, 1000);
            })
            .catch((error) => {
                console.error('Error retrieving search results:', error);
                setSearchResult([]);
            });
    };

    const branchOptions = [
        { label: 'Beauty Salon', value: 'beautySalon' },
        { label: 'Friseur', value: 'hairStudio' },
        { label: 'Massage', value: 'massage' },
        { label: 'Nagel Studio', value: 'nailStudio' },
        { label: 'Naturheilpraxis', value: 'naturheilPraxis' },
        { label: 'Physiotherapie', value: 'physioTherapy' },
        { label: 'Psychologie', value: 'psychoTherapy' },
        { label: 'Tattoo-Studio', value: 'tattooStudio' },
        { label: 'Waxing-Studio', value: 'waxingStudio' },
        { label: 'Yoga-Studio', value: 'yogaStudio' },
    ];

    const performInitialSearch = () => {

        setSearchResult('Performing search...');

        // let url = process.env.REACT_APP_BASE_URL_API + `/search?city=${city}&district=${district}&branch=${branch}`;
        let url = process.env.REACT_APP_BASE_URL_API + `/search?city=${city}&branch=${branch}`;

        console.log('Fetching search results:', url); // Debugging: Log the URL
        // Perform the search and update searchResult
        // Set isLoading to true before the search starts, and false when the search is complete

        setIsLoading(true);
        setSearchResult([]);

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                console.log('Search results received:', data);
                console.log('Updated search results:', Array.isArray(data) ? data : []);
                setTimeout(() => {
                    setSearchResult(Array.isArray(data) ? data : []);
                    setState(Array.isArray(data) ? data : []);
                    setIsLoading(false);
                    //navigate('/'); // Redirect to the home page
                }, 1000);
            })
            .catch((error) => {
                console.error('Error retrieving search results:', error);
                setSearchResult([]);
            });
    };

    useEffect(() => {

        performInitialSearch(); // Führen Sie die initiale Suche beim Laden aus
        console.log("Updated city: ", city);
        console.log("Updated branch: ", branch);

        // // performInitialSearch(); // Führen Sie die initiale Suche beim Laden aus

        // // Parse query parameters from the URL
        // const queryParams = queryString.parse(location.search);

        // // Update state with query parameter values if they exist
        // if (queryParams.city) {
        //     setCity(queryParams.city);
        // }
        // // if (queryParams.district) {
        // //     setDistrict(queryParams.district);
        // // }
        // if (queryParams.branch) {
        //     setBranch(queryParams.branch);
        // }

        // console.log("City Cookie: ", searchValues.city)

        // // Update dropdown values when searchValues context changes
        // setCity(searchValues.city || '');
        // // setDistrict(searchValues.district || '');
        // setBranch(searchValues.branch || '');

        // console.log("Updated city: ", city);

        // const searchValuesCookie = Cookies.get('searchValues');
        // if (searchValuesCookie) {
        //     const { city, district, branch } = JSON.parse(searchValuesCookie);
        //     setCity(city);
        //     setDistrict(district);
        //     setBranch(branch);
        // }


    // }, [location.search, searchValues, city]);
    }, [city]);

    const handleSearch = () => {
        // Build the new URL with search parameters
        console.log("City on handleSearch: ", city)
        const searchParams = queryString.stringify({ city, branch });
        const newUrl = `/?${searchParams}`;

        // Cookies.set('searchValues', JSON.stringify({ city, district, branch }));

        // Update the search context values
        setSearchValues({ city, branch });

        // Navigate to the new URL
        navigate(newUrl);

        // Scrollen Sie zum Element mit der ID #searchresult
        const element = document.getElementById('resultarea-header');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }

    };

    return (
        <>
            <div className=''>
                <div className="row d-md-block d-md-none rounded-pill mx-0 shadow py-2">
                    <div className="col-auto pe-0">
                        <button id="search-config" onClick={handleModalOpen} type="button" className="btn btn-lg btn-outline-light btn-circle" >
                            <Sliders color="black" />
                        </button>
                        {/* <div id="" className="btn btn-lg btn-outline-light btn-circle" >
                            <Sliders color="black" className='opacity-50' />
                        </div> */}
                    </div>
                    <div className="col text-center my-auto w-100">
                        {/* <div id="search-config" onClick={handleModalOpen} type="button" className="" >
                            <small className='d-block my-0 py-0 fw-bold'>{branchLabel}</small>
                            <small className='d-block my-0 py-0 opacity-50'>{city},  {district}</small>
                        </div> */}
                        <GooglePlaces
                            onCityChange={handleCityChange}
                        />
                            {/* onChange={(value) => setCity(value)}  */}
                    </div>
                    <div className="col-auto">
                        <form onSubmit={handleSubmit} className="">
                            <button type="submit" className="btn btn-lg btn-circle" onClick={handleSearch}>
                                <Search />
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal" id="searchModal">
                <div className="modal-dialog">
                    <div className="modal-content" style={{ zIndex: 1000 }}>
                        <div className="modal-header">
                            <h5 className="modal-title">Sucheinstellungen</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-4">
                            <form onSubmit={handleSubmit} className="align-items-center py-1">
                                <div className="row g-3">
                                    {/* <RoomSeatSelector id={1} /> */}

                                    <div className="my-4">
                                        <div className="my-3">
                                            {/* <label htmlFor="city" className="form-label">Deine Stadt</label> */}
                                            {/* <GooglePlaces
                                                onCityChange={handleCityChange}
                                            /> */}
                                            {/* <select 
                                                id="city"
                                                value={city}
                                                onChange={handleCityChange}
                                                className="form-select"
                                            >
                                                <option value="">Deine Stadt</option>
                                                <option value="Berlin">Berlin</option>
                                                <option value="Düsseldorf">Düsseldorf</option>
                                                <option value="Essen">Essen</option>
                                                <option value="Hamburg">Hamburg</option>
                                                <option value="Köln">Köln</option>
                                                <option value="München">München</option>
                                            </select> */}

                                        </div>
                                        {/* <div className="my-3">
                                            <label htmlFor="district" className="form-label">Dein Bezirk</label>
                                            <select
                                                id="district"
                                                value={district}
                                                onChange={handleDistrictChange}
                                                className="form-select"
                                            >
                                                <option value="">Dein Bezirk</option>
                                                {getDistrictOptions().map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div> */}
                                        <div className="my-3">
                                            <label htmlFor="branch" className="form-label">Branche</label>
                                            <select
                                                id="branch"
                                                value={branch}
                                                onChange={handleBranchChange}
                                                className="form-select"
                                            >
                                                <option value="" className="text-muted" disabled>Branche</option>
                                                {branchOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* <div className="my-4">
                                        <label htmlFor="timeslot" className="form-label">Timeslot</label>
                                        <Timeslots id="search-timeslots" />
                                    </div> */}
                                </div>
                            </form>

                            <div className='my-5 '><span className='opacity-50'>Du möchtest Arbeitsplätze oder Räume anbieten? Dann</span> <a href="/add" className='fw-bold'>hier eintragen</a></div>
                        </div>

                        <div className="modal-footer bg-white sticky-bottom">
                            <div className="d-flex justify-content-end">
                                <button type="submit" className="btn btn-lg btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={handleSearch}>
                                    <span className='me-2'><Search /></span> <span className='my-2'>Jetzt Suchen</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <form onSubmit={handleSubmit} className="row align-items-center border border-2 rounded-5 py-1 shadow shadow-md d-none d-md-block"> */}
            <div className="d-none d-md-block">
                <form onSubmit={handleSubmit} className="row align-items-center rounded-5 py-1 px-1 bg-white shadow-search" shadow shadow-md >
                    {/* <div className="col  ps-1 pe-1">
                        <select
                            id="district"
                            value={district}
                            onChange={handleDistrictChange}
                            className="form-control-search mr-2 ps-3 rounded-pill"
                        >
                            <option value="">Dein Bezirk</option>
                            {getDistrictOptions().map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div> */}
                    <div className="col  ps-1 pe-1">
                        <select
                            id="branch"
                            value={branch}
                            onChange={handleBranchChange}
                            className="form-control-search mr-2 ps-3 rounded-pill"
                        >
                            <option value="" className="text-muted" disabled>Branche</option>
                            {branchOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col ps-2 pe-1">
                        <GooglePlaces
                            onCityChange={handleCityChange}
                            // handleSubmit={handleSubmit}
                        />
                       
                    </div>
                    <div className="col-auto px-1 pe-0">
                        <button type="submit" className="btn btn-lg btn-light btn-circle" onClick={handleSearch}>
                            <Search />
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default SearchForm;
