import React, { useState, useEffect } from 'react';
import BookingListItem from '../components/BookingListItem';

const BookingScreen = () => {
    const [bookings, setBookings] = useState([]);
    const [filter, setFilter] = useState('all');

    useEffect(() => {
        // Fetch bookings from the API or use your own data source
        const fetchBookings = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_BASE_URL_API + '/bookings');
                const data = await response.json();
                setBookings(data);
            } catch (error) {
                console.error('Error fetching bookings:', error);
            }
        };
        fetchBookings();
        console.log(process.env.REACT_APP_BASE_URL_API);
        console.log(bookings);
    }, []);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filterBookings = (bookings, filter) => {
        if (filter === 'all') {
            return bookings;
        } else if (filter === 'new') {
            return bookings.filter((booking) => booking.status === 'new');
        } else if (filter === 'pending') {
            return bookings.filter((booking) => booking.status === 'pending');
        } else if (filter === 'confirmed') {
            return bookings.filter((booking) => booking.status === 'confirmed');
        } else if (filter === 'canceled') {
            return bookings.filter((booking) => booking.status === 'canceled');
        } else if (filter === 'closed') {
            return bookings.filter((booking) => booking.status === 'closed');
        }
        return bookings;
    };

    const filteredBookings = filterBookings(bookings, filter);

    return (
        <div className='container py-5 my-5'>
            <h1>Booking Requests</h1>
            <div className="mb-3">
                <label htmlFor="filter" className="form-label">Filter</label>
                <select id="filter" className="form-select" value={filter} onChange={handleFilterChange}>
                    <option value="all">All</option>
                    <option value="new">New</option>
                    <option value="confirmed">Confirmed</option>
                    <option value="canceled">Canceled</option>
                    <option value="closed">Closed</option>
                </select>
            </div>
            <ul className="list-group">
                {filteredBookings.map((booking) => (
                    <BookingListItem key={booking.id} booking={booking} />
                ))}
            </ul>
        </div>
    );
};

export default BookingScreen;
