import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const BookingSingleDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [booking, setBooking] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BASE_URL_API + `/bookings/${id}`);
        setBooking(response.data);
      } catch (error) {
        console.error('Error fetching booking details:', error);
      }
    };

    fetchBookingDetails();
  }, [id]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Show confirmation dialog before saving
    const confirmationResult = await Swal.fire({
        icon: 'question',
        title: 'Confirmation',
        text: 'Are you sure you want to save the changes?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      });
  
      if (confirmationResult.isConfirmed) {
        try {
          const response = await axios.put(process.env.REACT_APP_BASE_URL_API + `/bookings/${id}`, booking);
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Booking updated successfully!',
          });
          console.log('Booking updated:', response.data);
          // Perform any necessary actions after successful update
          navigate('/bookings'); // Redirect to /bookings
        } catch (error) {
          console.error('Error updating booking:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to update booking.',
          });
        }
      }
    };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBooking((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className='container mt-5 py-5 px-4'>
      <h2>{t('bookingDetail')}</h2>
      {booking ? (
        <form onSubmit={handleFormSubmit}>
          <div className='mb-3'>
            <label htmlFor='firstName' className='form-label'>
              {t('First_Name')}
            </label>
            <input
              type='text'
              className='form-control'
              id='firstName'
              name='firstName'
              value={booking.firstName}
              onChange={handleInputChange}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='lastName' className='form-label'>
              {t('Last_Name')}
            </label>
            <input
              type='text'
              className='form-control'
              id='lastName'
              name='lastName'
              value={booking.lastName}
              onChange={handleInputChange}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='email' className='form-label'>
              {t('Email')}
            </label>
            <input
              type='email'
              className='form-control'
              id='email'
              name='email'
              value={booking.email}
              onChange={handleInputChange}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='phone' className='form-label'>
              {t('Phone')}
            </label>
            <input
              type='tel'
              className='form-control'
              id='phone'
              name='phone'
              value={booking.phone}
              onChange={handleInputChange}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='status' className='form-label'>
              {t('Status')}
            </label>
            <select
              className='form-select'
              id='status'
              name='status'
              value={booking.status}
              onChange={handleInputChange}
            >
              <option value='new'>{t('New')}</option>
              <option value='pending'>{t('Pending')}</option>
              <option value='confirmed'>{t('Confirmed')}</option>
              <option value='cancelled'>{t('Cancelled')}</option>
            </select>
          </div>
          <div className='mb-3'>
            <label htmlFor='bookingDuration' className='form-label'>
              {t('bookingDurations')}
            </label>
            <input
              type='text'
              className='form-control'
              id='bookingDuration'
              name='bookingDuration'
              value={booking.bookingDuration}
              onChange={handleInputChange}
            />
          </div>
          {/* Add inputs for other booking details */}
          <button type='submit' className='btn btn-primary'>
            {t('saveChanges')}
          </button>
        </form>
      ) : (
        <div>{t('loadingBookingDetails')}</div>
      )}
    </div>
  );
};

export default BookingSingleDetail;
