import React from 'react';
import { useLocation } from 'react-router-dom';
import BookingForm from './BookingForm';
import 'bootstrap/dist/css/bootstrap.css';
import { Modal } from 'bootstrap';

const BookingFormModal = ({ entity }) => {

  const location = useLocation();
  const shouldBookingFormModal = location.pathname.startsWith('/entity');
  const [formData, setFormData] = React.useState({});

  const [modalStep, setModalStep] = React.useState(1);

  const handleModalOpen = () => {
    const modalElement = document.getElementById('bookingModal');
    const modal = new Modal(modalElement);
    modal.show();
  };

  const handleModalNextStep = () => {
    setModalStep(modalStep + 1);
  };

  const handleModalPreviousStep = () => {
    setModalStep(modalStep - 1);
  };

  const handleFormSubmit = async (formData) => {
    // Handle form submission logic here, such as making API requests or updating the state
    console.log('Form submitted:', formData);
    setFormData(formData);
    console.log('Booking Form', formData);
    // event.preventDefault();
    // handleSubmitForm(formData);

    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL_API + '/addbooking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        // Handle successful booking submission
        setFormData(formData);
        // handleSubmitForm(formData);
      } else {
        // Handle error response
        console.error('Error submitting booking');
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error submitting booking:', error);
    }
  };

  return (
    <>
      {shouldBookingFormModal && (
        <>
          <div
            id="booking-footer"
            className="bg-white border-top d-flex justify-content-end align-items-center p-3 stick-footer"
          >
            <div className='me-4 opacity-50'>Buche jetzt deinen Cobee-Platz</div>
            <div className="text-end">
              <button
                id="booking-footer-button"
                type="button"
                className="btn btn-lg btn-primary"
                onClick={handleModalOpen}
              > Buchungsanfrage
              </button>
            </div>
          </div>

          <div className="modal" id="bookingModal">
            <div className="modal-dialog">
              <div className="modal-content h-100 min-vh-md-auto">
                <div className="modal-header sticky-top bg-white">
                  <h5 className="modal-title">
                    {modalStep === 1 && '1/4'}
                    {modalStep === 2 && '2/4'}
                    {modalStep === 3 && '3/4'}
                    {modalStep === 4 && '4/4'}
                  </h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body p-4">
                  {/* <BookingForm step={modalStep} handlePreviousStep={handleModalPreviousStep} entity={entity}/>
                   */}

                  <BookingForm
                    step={modalStep}
                    handlePreviousStep={handleModalPreviousStep}
                    handleFormSubmit={handleFormSubmit} // Pass the submit handler function
                    entity={entity}
                  />
                </div>
                <div className="modal-footer bg-white stick-footer">
                  <div className='d-flex me-auto ps-4'>
                    <div className='col-auto me-4 my-auto'>
                      <h6>Gesamtpreis</h6>
                    </div>
                    <div className='col-auto '>
                      <div id="monthlyTotal" className='fs-3 fw-bold lh-1 d-block text-center'>{entity.rateDay} €</div>
                      <small className='d-block text-muted opacity-50'><span id="display-subscription-type"></span>zzgl. UmSt. & Servicegebühr</small>
                    </div>
                  </div>
                  {modalStep === 1 && (
                    <button type="button" className="btn btn-lg" data-bs-dismiss="modal" aria-label="Close">Zurück</button>
                  )}
                  {modalStep !== 1 && (
                    <button className="btn btn-lg btn-outline" onClick={handleModalPreviousStep}>
                      Zurück
                    </button>
                  )}
                  {modalStep !== 4 ? (
                    <button className="btn btn-lg btn-dark" onClick={handleModalNextStep}>
                      Weiter
                    </button>
                  ) : (
                    <div id="move-submit-bookingform-btn">
                      {/* <button id="booking-footer-button" type="button" className="btn btn-lg btn-primary" onClick={handleFormSubmit}> Buchen</button> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BookingFormModal;