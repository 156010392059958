import React, { useState } from 'react';

const BookingScreen = () => {
    const [step, setStep] = useState(1);
    const [bookingData, setBookingData] = useState({
        name: '',
        email: '',
        date: '',
        time: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBookingData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleNextStep = (e) => {
        e.preventDefault();
        setStep((prevStep) => prevStep + 1);
    };

    const handlePreviousStep = (e) => {
        e.preventDefault();
        setStep((prevStep) => prevStep - 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform booking logic here
        console.log('Booking submitted:', bookingData);
        // Reset the form
        setBookingData({
            name: '',
            email: '',
            date: '',
            time: '',
        });
        setStep(1);
    };

    const renderForm = () => {
        switch (step) {
            case 1:
                return (
                    <div className="step">
                        <h2>Personal Details</h2>
                        <hr></hr>
                        <div className="mb-3">
                            <input type="text" id="name" name="name" value={bookingData.name} onChange={handleChange} className="form-control" placeholder='Name' />
                        </div>
                        <div className="mb-3">
                            <input type="email" id="email" name="email" value={bookingData.email} onChange={handleChange} className="form-control" placeholder='Email' />
                        </div>
                        <hr></hr>
                        <button className="btn btn-lg btn-primary rounded-pill" onClick={handleNextStep}>Next</button>
                    </div>
                );
            case 2:
                return (
                    <div className="step">
                        <h2>Date and Time</h2>
                        <hr></hr>
                        <div className="mb-3">
                            <label htmlFor="date" className="form-label">Date:</label>
                            <input type="date" id="date" name="date" value={bookingData.date} onChange={handleChange} className="form-control" placeholder='Name' />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="time" className="form-label">Time:</label>
                            <input type="time" id="time" name="time" value={bookingData.time} onChange={handleChange} className="form-control" placeholder='Name' />
                        </div>
                        <hr></hr>
                        <button className="btn btn-lg btn-outline rounded-pill" onClick={handlePreviousStep}>Previous</button>
                        <button className="btn btn-lg btn-primary rounded-pill" onClick={handleNextStep}>Next</button>
                    </div>
                );
            case 3:
                return (
                    <div className="step">
                        <h2>Confirmation</h2>
                        <hr></hr>
                        <label className='opacity-50'>Name</label>
                        <div className='h5'>{bookingData.name}</div>
                        <label className='opacity-50'>Email</label>
                        <div className='h5'>{bookingData.email}</div>
                        <label className='opacity-50'>Date</label>
                        <div className='h5'>{bookingData.date}</div>
                        <label className='opacity-50'>Time</label>
                        <div className='h5'>{bookingData.time}</div>
                        <hr></hr>
                        <button className="btn btn-lg btn-outline rounded-pill" onClick={handlePreviousStep}>Previous</button>
                        <button className="btn btn-lg btn-primary rounded-pill" onClick={handleSubmit}>Book</button>
                    </div>
                );
            default:
                return null;
        }
    };


    return (
        <div className='container shadow mt-5 p-5 rounded rounded-xl'>
            <h1>Booking Screen</h1>
            <div className="booking-form">
                {renderForm()}
            </div>
        </div>
    );
};

export default BookingScreen;
