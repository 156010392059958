import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';


const EmailVerificationScreen = () => {
    const { vid, email } = useParams();
    const [verificationStatus, setVerificationStatus] = useState(null);

    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL_API + `/verify-email/${vid}/${email}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Verifizierung fehlgeschlagen');
                }
                return response.json();
            })
            .then(data => {
                setVerificationStatus('Erfolgreich verifiziert');
            })
            .catch(error => {
                setVerificationStatus('Verifizierung fehlgeschlagen');
            });
    }, [vid, email]);

    return (
        <div className='container d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
            <div className='text-center mt-5 mb-5 h-100'></div> 
            {verificationStatus ? (
                <div className={verificationStatus === 'Erfolgreich verifiziert' ? 'text-success' : 'text-danger'}>
                    <div className='text-center mt-5 mb-5 h-100'>
                        {verificationStatus === 'Erfolgreich verifiziert' ? (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-center justify-content-center mx-auto" height="100px">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-center justify-content-center mx-auto" height="100px">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 2C6.48 2 2 6.48 2 12c0 5.52 4.48 10 10 10s10-4.48 10-10c0-5.52-4.48-10-10-10zm0 16v-2m0-4h.01M12 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm0 6v2" />
                            </svg>
                        )}
                        <h4 className='text-center'>
                            {verificationStatus} <i className="bi bi-check-circle-fill"></i>
                        </h4>
                    </div>
                </div>
            ) : (
                <div>{vid} Verifizierung...</div>
            )}
        </div>
    );
};     

export default EmailVerificationScreen;