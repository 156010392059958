import React, { createContext, useState } from 'react';

// Create a context
export const MyContext = createContext();

// Create a provider component
export const MyProvider = ({ children }) => {
    const [state, setState] = useState('');

    return (
        <MyContext.Provider value={{ state, setState }}>
            {children}
        </MyContext.Provider>
    );
};
