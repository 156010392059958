import React, { useState } from 'react';
import Card from '../components/Card';
import SearchForm from '../components/SearchForm';
import SearchResults from '../components/SearchResults';
import OfferSection from '../components/OfferSection';
import '../screens/carousel.css';

const Home = () => {
	const [searchQuery, setSearchQuery] = useState('');

	const handleSearch = (query) => {
		setSearchQuery(query);
	};

	return (
		<div className="home">
			<div className="py-5 text-left border-bottom min-vh-50 text-light" style={{
				backgroundImage: "url('../bg-halftone-swing.png')",
				// backgroundImage: "url('../bg-halftone-swing.png')",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundPosition: "center"
			}}>
				<div className='container d-flex justify-content-start h-100'>
					<div className="col-md-5 lead py-5 my-auto" style={{ wordWrap: 'anywhere' }}>
						<h1 className="display-4 fw-bold d-none d-md-block">Buche deinen Arbeitsplatz</h1>
						<h2 className="display-6 fw-bold d-md-none">Buche deinen Arbeitsplatz</h2>
						
						<p className="">Willkommen bei Cobee.me - Ihrer Lösung für die Buchung von professionellen Arbeitsplätzen in den Bereichen Beauty & Health.</p>						
						{/* <div className="d-block mt-4">
							<a href="/add/" className="btn btn-outline-secondary bg-white text-dark py-3 px-5" btn-lg>
								Platz Anbieten
							</a>
						</div> */}
					</div>
				</div>
			</div>

			<div id="resultarea-header" className="container-fluid px-4 pt-4 text-center">
				{/* <SearchForm onSearch={handleSearch} /> */}
				{/* <div className="mt-2">{searchResult}</div> */}
				<div className="container-sm py-4 my-4">
					<h2>Finde deine neue Arbeitsumgebung</h2>
					<p className='lead'>Miete einfach und bequem deinen Arbeitsplatz in den Bereichen Kosmetik, Waxing, Massage, Friseur, Yoga, Physiotherapie, Psychologie oder Tattoo.</p>
				</div>
			</div>
			<div className="container-fluid px-4 pt-4 text-center">
				<SearchResults searchQuery={searchQuery} />
			</div>

			{/* <OfferSection searchQuery={searchQuery}/> */}

			<div className="container-xl px-4 pt-4 text-center">
				<div className='container-fluid'>
					<div className="row featurette">
						<div className="col-md-6 my-auto p-5">
							<h2 className="fw-normal lh-1 fs-1 fw-bold" featurette-heading><a href="/vermieter-information/">Stärke dein Business
								<span className="text-body-secondary d-block opacity-50">– Raumvermietung leicht gemacht mit Cobee.me!</span></a></h2>
							<p className="lead">Entdecke die moderne Art der Raumvermietung. Maximiere Einnahmen, fördere Synergien und minimiere den Aufwand.</p>
							<a href='/add/' className="btn btn-primary btn-lg me-2">Jetzt Arbeitsplatz anbieten</a>
							<a href='/vermieter-information/' className="btn btn-outline btn-lg">Mehr Infos</a>
						</div>
						<div className="col-md-6">
							<img src="/bg-cobee-3.png" className="rounded" width="100%" />
						</div>
					</div>
					<div className="row featurette">
						<div className="col-md-6 order-md-2 my-auto p-5">
							<h2 className="fw-normal lh-1 fs-1 fw-bol" featurette-heading><a href="/mieter-information/">Finde deinen Traum-Arbeitsplatz mit Cobee.me!</a></h2>
							<p className="lead">Egal ob Kosmetik, Yoga oder Therapie – Entdecke die perfekte Arbeitsumgebung für dein Business.</p>
							<a href='/mieter-information/' className="btn btn-outline btn-lg">Mehr Infos</a>
						</div>
						<div className="col-md-6 order-md-1">
							<img src="/SVG/round-logo.svg" className="rounded p-5" width="400px" />
						</div>
					</div>
					{/* <div class="px-4 py-5 my-5 text-center">
						<h1 class="display-5 fw-bold text-body-emphasis">Jetzt deinen Arbeitsplatz Vermieten</h1>
						<div class="col-lg-6 mx-auto">
						<p class="lead mb-4">Quickly design and customize responsive mobile-first sites with Bootstrap, the world’s most popular front-end open source toolkit, featuring Sass variables and mixins, responsive grid system, extensive prebuilt components, and powerful JavaScript plugins.</p>
						<div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
							<button type="button" class="btn btn-primary btn-lg px-4 gap-3">Primary button</button>
							<button type="button" class="btn btn-outline-secondary btn-lg px-4">Secondary</button>
						</div>
						</div>
					</div> */}
					{/* <div className="row featurette">
						<div className="col-md-6 my-auto p-5">
							<h2 className="fw-normal lh-1 fs-1 fw-bold" featurette-heading>First featurette heading.
								<span className="text-body-secondary d-block opacity-50">It’ll blow your mind.</span></h2>
							<p className="lead">Some great placeholder content for the first featurette here. Imagine some exciting prose here.</p>
						</div>
						<div className="col-md-6">
							<img src="/cobee-halftone-light.png" className="rounded" width="100%" />
						</div>
					</div> */}
				</div>
			</div>
			{/* {posts.map(post => (
                <Card key={post.id} post={post} />
            ))} */}
		</div>
	);
};

export default Home;
