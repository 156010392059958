import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const LoadingSplashScreen = () => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    window.onload = () => {
      setFadeOut(true);
    };
  }, []);

  const splashScreenStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    opacity: fadeOut ? 0 : 1,
    transition: 'opacity 2000ms ease',
    backgroundColor: '',
  };

  return (
    <div className="loading-splash-screen" style={splashScreenStyles}>
      {/* <h1 style={h1Styles}>Loading...</h1> */}
      <FontAwesomeIcon icon={faCircleNotch} spin size="3x" />
    </div>
  );
};

export default LoadingSplashScreen;
