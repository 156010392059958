import React, { useState } from 'react';

const CustomAccessoriesComponent = ({ onCustomAccessoriesChange }) => {
    const [customAccessories, setCustomAccessories] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const handleKeyDown = (e) => {
        if (e.key === ',') {
            e.preventDefault();
            const trimmedAccessory = inputValue.trim();
            if (trimmedAccessory !== '' && !customAccessories.includes(trimmedAccessory)) {
                setCustomAccessories((prevAccessories) => {
                    const newAccessories = [...prevAccessories, trimmedAccessory];
                    onCustomAccessoriesChange(newAccessories);
                    return newAccessories;
                });
                setInputValue('');
            }
        }
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleRemove = (accessoryToRemove) => {
        setCustomAccessories((prevAccessories) => {
            const newAccessories = prevAccessories.filter(accessory => accessory !== accessoryToRemove);
            onCustomAccessoriesChange(newAccessories);
            return newAccessories;
        });
    };
 
    return (
        <>
            {customAccessories.map((accessory, index) => (
                <span>
                    <span className="d-block text-dark" key={index}>
                        {accessory}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-x-circle-fill ms-2" viewBox="0 0 16 16" onClick={() => handleRemove(accessory)}>
                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.354 11.354a.5.5 0 0 1-.708 0L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 .708-.708L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1 0 .708z"/>
                        </svg>
                    </span>
                </span>
            ))}
            <input
                type="text"
                className="form-control"
                placeholder="Individuelle Ausstattungsmerkmale"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
            />
            <label className="form-label">Jeden Begriff mit Komma trennen</label>
        </>
    );
}

export default CustomAccessoriesComponent;