import React, { useState, useEffect, useContext } from 'react';
import ReactLoading from 'react-loading';
import { MyContext } from './Context';
import ImageSlider from './ImageSliderNew';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChair, faCube, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function SearchResults() {
    const { state } = useContext(MyContext);
    const [isLoading, setIsLoading] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [limit, setLimit] = useState(10);
    const { t } = useTranslation(); // Use useTranslation hook to access the translation function

    const loadMore = () => {
        setLimit((prevLimit) => prevLimit + 10);
    };

    useEffect(() => {
        setSearchResult([]);
        setIsLoading(true);

        // Simulating an asynchronous search request
        const fetchData = async () => {
            try {
                // Your actual data fetching logic should go here
                // For now, using a mock delay
                await new Promise((resolve) => setTimeout(resolve, 2000));
                setSearchResult((prevResults) => [...prevResults, ...state.slice(0, limit)]);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const timeoutId = setTimeout(() => {
            fetchData();
        }, 2000);

        // Cleanup function to handle potential component unmount
        return () => clearTimeout(timeoutId);
    }, [limit, state]);

    return (
        <div id="resultarea-header" className="mt-2">
            {isLoading ? (
                <div className="row">
                    <div className="d-flex justify-content-center align-items-center">
						<ReactLoading type="spin" color="#000000" height={50} width={50} />
                    </div>
                </div>
            ) : Array.isArray(state) && state.length > 0 ? (
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6 g-4">
                    {searchResult.map((result, index) => (
                        <div key={result._id} className="col fade-in" style={{ animationDelay: `${index * 100}ms` }}>
							<div className="card">
								{result.images &&
									result.images.length > 0 && (
										<ImageSlider
											id={`slider_${result._id}`}
											_id={`${result._id}`}
											images={result.images}
										/>
									)}
								{/* {result.priceDay && (
									<div className='position-absolute bg-light ms-2 rounded-4 mt-2 py-0 px-2'>
										<small className="text-muted opacity-50 smaller">Ab </small>
										<span className='text-dark fs-4 fw-bold lh-0'>{result.priceDay} €*</span>
									</div>
								)} */}
								{/* <img src={result.image_url} className="rounded-xxl" alt={result.title} /> */}
								<a href={`/entity/${result._id}`} className="card-link">
									<div className="card-body px-1 py-2">
										<div className="card-title">{result.title}</div>
									</div>
									<div className='card-text'>
										<div className='row'>
											<div className='col-6'>
												<div className='small opacity-50'><FontAwesomeIcon icon={faLocationDot} className='opacity-25 me-2' /> {result.city}, {result.district} </div>
												<div className='small opacity-50'>
                                                {result.selectedEntity.includes('seat') ? (
                                                    <>
                                                        <FontAwesomeIcon icon={faChair} className='opacity-25 me-2' />
                                                        {t('seat')} {/* Assuming 'seat' is the translation key for 'Seat' */}
                                                    </>
                                                ) : result.selectedEntity.includes('room') ? (
                                                    <>
                                                        <FontAwesomeIcon icon={faCube} className='opacity-25 me-2' />
                                                        {result.squaremeter}m<sup>2</sup> {t('room')} {/* Assuming 'room' is the translation key for 'Room' */}
                                                    </>
                                                ) : (
                                                    <>
                                                        {result.squaremeter}m<sup>2</sup>
                                                    </>
                                                )}
                                                </div>
											</div>
											<div className='col-6 d-flex justify-content-end'>
												<div className='bg-dark rounded-4 mt-2 py-1 px-3 text-center d-inline mt-auto ms-auto'>
													{/* <small className="text-light smaller">ab </small> */}
													<span className='text-light fs-7 fw-bold lh-0'>ab {(Math.round(result.rateDay * 0.8 * 4))} €</span>
													<small className="text-light smaller"></small>
												</div>
											</div>
										</div>
										{/* <p className="card-text">{result.description}</p> */}
									</div>
								</a>
							</div>
						</div>
                    ))}
					
                    {/* <button onClick={loadMore} className='d-block btn btn-primary'>Mehr laden</button> */}
                </div>
            ) : (
                <div className="text-dark py-2"></div>
            )}
        </div>
    );
}

export default SearchResults;
