import React from 'react';

const OpeningHours = () => {

    const openingHours = {
        weekdays: '08:00 - 18:00 Uhr',
        saturday: '08:00 - 16:00 Uhr',
    };

    return (
        <div>
            <h4 className="mb-0">Öffnungszeiten</h4>
            <table className="table ">
                <tbody>
                    <tr>
                        <td>Mo-Fr:</td>
                        <td>{openingHours.weekdays}</td>
                    </tr>
                    <tr>
                        <td>Sa:</td>
                        <td>{openingHours.saturday}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default OpeningHours;
