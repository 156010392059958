import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: {
				welcome: 'Welcome',
				about: 'About'
			},
			translation: {
				welcome: 'Welcome',
				about: 'About'
			}
		},
		de: {
			translation: {
				welcome: 'Willkommen',
				title: 'Titel',
				Description: 'Beschreibung',
				Email: 'Email',
				Phone: 'Telefon',
				First_Name: 'Vorname',
				bookingDetail: 'Buchung',
				bookingDurations: 'Mietdauer',
				loadingBookingDetails: 'Laden der Buchungsdaten...',
				Last_Name: 'Nachname',
				saveChanges: 'Speichern',
				Room: 'Raum',
				Seat: 'Arbeitsplatz',
				Previous: 'Zurück',
				Next: 'Weiter',
				Monday: 'Montag',
				Tuesday: 'Dienstag',
				Wednesday: 'Mittwoch',
				Thursday: 'Donnerstag',
				Friday: 'Freitag',
				Saturday: 'Samstag',
				Sunday: 'Sonntag',
				hourly: 'Stündlich',
				twoHours: '2 Stunden',
				halfDay: 'Halbtägig',
				fullDay: 'Ganztägig',
				weekdays: {
					"monday": "Montag",
					"tuesday": "Dienstag",
					"wednesday": "Mittwoch",
					"thursday": "Donnerstag",
					"friday": "Freitag",
					"saturday": "Samstag",
					"sunday": "Sonntag",
					"montag": "Mo.",
					"dienstag": "Di.",
					"mittwoch": "Mi.",
					"donnerstag": "Do.",
					"freitag": "Fr.",
					"samstag": "Sa.",
					"sonntag": "So.",
				},
				bookingForm: {
					selectTimeslotSize: "Zeitfenstergröße",
					timeslot300: "Halbtägig",
					timeslot600: "Ganztägig",
				},
				bookingDuration: {
					ongoing: "Fortlaufend",
					threeMonths: "3 Monate",
					fourMonths: "1 Monat"
				},
				Salutation: "Anrede",
				mr: "Herr",
				mrs: "Frau",
				Firstname: "Vorname",
				Lastname: "Nachname",
				Telephone: "Telefon",
				Street: "Straße",
				streetNumber: "Hausnummer",
				city: "Stadt",
				categories: {
				},
				hairStudio: "Friseur",
				naturheilpraxis: "Naturheilpraxis",
				beautySalon: "Schönheitssalon",
				massage: "Massage",
				physiotherapie: "Physiotherapie",
				nagelstudio: "Nagelstudio",
				waxing: "Waxing",
				yoga: "Yoga",
				psychologie: "Psychologie",
				tattoo: "Tattoo",
				seat: "Arbeitsplatz",
				room: "Raum",
				Submit: "Jetzt unverbindlich inserieren",

				
			},
				
		},
		fr: {
			translation: {
				welcome: 'Bienvenue',
				about: 'À propos'
			}
		}
	},
	lng: 'de', // Default language
	fallbackLng: 'de', // Fallback language
	interpolation: {
		escapeValue: false
	}
});

export default i18n;
