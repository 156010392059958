import React from 'react';

const VermietereInformation = () => {
  return (
    <div className="mt-4">
      <div className="px-4 py-5 my-5 text-center">
        {/* <img className="d-block mx-auto mb-4" src="/docs/5.3/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57"/> */}
        <h1 className="display-5 fw-bold text-body-emphasis">Stärke dein Business</h1>
        <h5 className="display-5 fw-bold text-body-emphasis opacity-50">Maximiere deinen Erfolg durch Raumvermietung mit Cobee.me!</h5>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">Willkommen bei Cobee.me! Egal, ob du ein Kosmetikstudio, Yoga-Studio, Friseursalon oder eine Physiotherapie-Praxis betreibst – hier kannst du nicht nur Räumlichkeiten vermieten, sondern auch dein Business nachhaltig stärken.</p>
          {/* <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <button type="button" className="btn btn-primary btn-lg px-4 gap-3">Primary button</button>
            <button type="button" className="btn btn-outline-secondary btn-lg px-4">Secondary</button>
          </div> */}
        </div>
      </div>
      <div className='p-5' style={{
				backgroundImage: "url('../bg-halftone-swing.png')",
				// backgroundImage: "url('../bg-halftone-swing.png')",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundPosition: "center"
			}}>
      <h2 className="pb-2 text-white text-center">Warum Cobee für deine Vermietung?</h2>  
      </div>
      
      <div className="container px-4 py-5">
      {/* <h2 className="pb-2 border-bottom">Warum Cobee für deine Vermietung?</h2> */}

        <div className="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-5">
          <div className="col d-flex flex-column align-items-start gap-2">
            <h2 className="fw-bold text-body-emphasis">Deine Vorteile, wenn du Cobee.me für deine Vermietung nutzt</h2>
            <p className="text-body-secondary">Nutze jetzt Cobee.me und profitiere von einer stressfreien Vermietung für dein Business. Verbinde dich mit qualifizierten Freiberuflern und minimiere Leerstand für maximale Einnahmen..</p>
            {/* <a href="#" className="btn btn-primary btn-lg">Primary button</a> */}
          </div>

          <div className="col">
            <div className="row row-cols-1 row-cols-sm-2 g-4">
              <div className="col d-flex flex-column gap-2">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-secondary bg-brand bg-gradient fs-4 rounded-3">
                  <svg className="bi" width="1em" height="1em">
                  </svg>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">Gezielte Vermittlung von Freelancern</h4>
                <p className="text-body-secondary">Verbinde dich mit qualifizierten Freiberuflern und minimiere Leerstand für maximale Einnahmen.</p>
              </div>

              <div className="col d-flex flex-column gap-2">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-secondary bg-brand bg-gradient fs-4 rounded-3">
                  <svg className="bi" width="1em" height="1em">
                  </svg>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">Komplett-Service für Standortbetreiber</h4>
                <p className="text-body-secondary">Cobee kümmert sich um den gesamten Vermietungsprozess – von der Mietersuche bis zur Vertragsabwicklung.</p>
              </div>

              <div className="col d-flex flex-column gap-2">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-secondary bg-brand bg-gradient fs-4 rounded-3">
                  <svg className="bi" width="1em" height="1em">
                  </svg>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">Synergieeffekte</h4>
                <p className="text-body-secondary">Profitiere von einem harmonischen Arbeitskollektiv! Durch die Erweiterung deines Dienstleistungsspektrums schaffst du nicht nur eine inspirierende Umgebung, sondern förderst auch Synergien. Die Kundenfrequenz steigt und damit auch die Bekanntheit deines Angebots und deiner Location.</p>
              </div>

              <div className="col d-flex flex-column gap-2">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-secondary bg-brand bg-gradient fs-4 rounded-3">
                  <svg className="bi" width="1em" height="1em">
                  </svg>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">Transparente Kostenstruktur</h4>
                <p className="text-body-secondary">Cobee steht für Transparenz – eine Service-Gebühr von 3% für Vermieter und 6% für den Mieter auf alle erfolgreichen vermittelten Vermietungen. Keine versteckten Kosten, volle Klarheit für beide Seiten.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='p-5' style={{
				backgroundImage: "url('../bg-halftone-swing.png')",
				// backgroundImage: "url('../bg-halftone-swing.png')",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundPosition: "center"
			}}>
      <h2 className="pb-2 text-white text-center">Wie Cobee.me dein Business stärkt</h2>  
      </div>

      <div className="container px-4 py-5" id="featured-3">
    {/* <h2 className="pb-2 border-bottom">Wie Cobee.me dein Business stärkt</h2> */}
    <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
      <div className="feature col">
        <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-secondary bg-brand bg-gradient fs-2 mb-3">+</div>
        <h3 className="fs-2 text-body-emphasis">Effiziente Zahlungsabwicklung</h3>
        <p>Stressfrei pünktliche Mietzahlungen erhalten und dich auf dein Geschäft konzentrieren.</p>
        <a href="#" className="icon-link">
          Call to action
        </a>
      </div>
      <div className="feature col">
        <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-secondary bg-brand bg-gradient fs-2 mb-3">+</div>
        <h3 className="fs-2 text-body-emphasis">Kein administrativer Aufwand</h3>
        <p>Cobee übernimmt die Bürokratie – von Vertragsangelegenheiten bis zur Abwicklung von Kündigungen.</p>
        <a href="#" className="icon-link">
          Call to action
        </a>
      </div>
      <div className="feature col">
        <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-secondary bg-brand bg-gradient fs-2 mb-3">+
        </div>
        <h3 className="fs-2 text-body-emphasis">Jetzt eintragen</h3>
        <p>Jetzt Arbeitsplatz anbieten und die Vorteile der stressfreien Vermietung nutzen.</p>
        {/* <a href="#" className="icon-link btn btn-primary"> */}
          {/* Call to action */}
        {/* </a> */}
      </div>
    </div>
  </div>
  <div className="container my-5">
  <div className="p-5 text-center rounded-3">
    {/* <h1 className="text-body-emphasis">Jetzt Eintragen</h1> */}
    <p className="col-lg-8 mx-auto fs-5 text-muted">
      Jetzt Arbeitsplatz anbieten und die Vorteile der stressfreien Vermietung nutzen.
    </p>
    <div className="d-inline-flex gap-2 mb-5">
      <a href="/add/" className="d-inline-flex align-items-center btn btn-primary btn-lg px-4 rounded-pill" type="button">
        Jetzt Arbeitsplatz eintragen
      </a>
      {/* <button className="btn btn-outline-secondary btn-lg px-4 rounded-pill" type="button"> */}
        {/* Kontakt */}
      {/* </button> */}
    </div>
  </div>
</div>
    </div>
  );
};

export default VermietereInformation;
