import React from 'react';
import { useTranslation } from 'react-i18next';

const WeekdaySelector = ({ selectedWeekdays, onWeekdayChange }) => {
    const { t } = useTranslation();

    const weekdays = [
        { value: 'Monday', label: t('Monday') },
        { value: 'Tuesday', label: t('Tuesday') },
        { value: 'Wednesday', label: t('Wednesday') },
        { value: 'Thursday', label: t('Thursday') },
        { value: 'Friday', label: t('Friday') },
        { value: 'Saturday', label: t('Saturday') },
        { value: 'Sunday', label: t('Sunday') },
    ];

    const handleWeekdayChange = (event) => {
        const { value, checked } = event.target;
        let updatedSelectedWeekdays;

        if (checked) {
            updatedSelectedWeekdays = [...selectedWeekdays, value];
        } else {
            updatedSelectedWeekdays = selectedWeekdays.filter(day => day !== value);
        }

        onWeekdayChange(updatedSelectedWeekdays);
    };

    const isWeekdaySelected = (weekday) => selectedWeekdays.includes(weekday);

    return (
        <div className="form-group row mb-3">
            <div className="col-sm-12 ">
                <h6 htmlFor="total-seat-count" className="col-sm-12 col-form-label fw-bold">An welchen Tagen möchtest du vermieten?</h6>
                <div className="form-text mt-0 mb-2 opacity-50">Wähle die Wochentage aus, an dem du deinen Platz anbieten möchtest.</div>
                <div className='mt-3 mb-4'>
                    <div className="btn-group">
                        <input
                            type="radio"
                            id="mon-fri"
                            name="weekdays-option"
                            value="mon-fri"
                            className="form-check-input visually-hidden"
                            checked={selectedWeekdays.length === 5}
                            onChange={() => onWeekdayChange(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'])}
                            />
                        <label
                            htmlFor="mon-fri"
                            className={`form-check-label rounded-pill border border-1 border-dark me-2 ${selectedWeekdays.length === 5 ? 'checked' : ''}`}
                        >
                            Mo. - Fr.
                        </label>
                    </div>
                    <div className="btn-group">
                        <input
                            type="radio"
                            id="workday"
                            name="workday"
                            value="workday"
                            className="form-check-input visually-hidden"
                            checked={selectedWeekdays.length === 6}
                            onChange={() => onWeekdayChange(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'])}
                            />
                        <label
                            htmlFor="workday"
                            className={`form-check-label rounded-pill border border-1 border-dark me-2 ${selectedWeekdays.length === 6 ? 'checked' : ''}`}
                        >
                            Werktage
                        </label>
                    </div>

                    <div className="btn-group">
                        <input
                            type="checkbox"
                            id="full-week"
                            name="full-week"
                            value="full-week"
                            className="form-check-input visually-hidden"
                            checked={selectedWeekdays.length === 7}
                            onChange={() => onWeekdayChange(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'])}
                            />
                        <label
                            htmlFor="full-week"
                            className={`form-check-label rounded-pill border border-1 border-dark me-2 ${selectedWeekdays.length === 7 ? 'checked' : ''}`}
                        >
                            Ganze Woche
                        </label>
                    </div>

                </div>

                <div className="">

                    {/* Mobile View */}
                    <div className="d-md-none">
                        {weekdays.map((weekday) => (
                            <div className="btn-group d-block mb-2" key={weekday.value}>
                                <input
                                    type="checkbox"
                                    id={weekday.value}
                                    name="weekday"
                                    value={weekday.value}
                                    className="form-check-input visually-hidden"
                                    checked={isWeekdaySelected(weekday.value)}
                                    onChange={handleWeekdayChange}
                                />
                                {/* {isWeekdaySelected(weekday.value) + '=' + weekday.value } */}
                                {/* {isWeekdaySelected(weekday.value) == true ? 'checked' : ''} */}
                                {/* {`${ isWeekdaySelected(weekday.value) === weekday.value ? 'selected' : 'not selected' }`}             */}
                                <label
                                    htmlFor={weekday.value}
                                    className={`w-100 text-center form-check-label rounded-pill border border-1 border-dark me-2 ${isWeekdaySelected(weekday.value) ? 'checked' : ''}`}
                                    >
                                    {weekday.label}
                                </label>
                            </div>
                        ))}
                    </div>

                    {/* Wider Screens */}
                    <div className="d-none d-md-flex">
                        {weekdays.map((weekday) => (
                            <div className="btn-group" key={weekday.value}>
                                <input
                                    type="checkbox"
                                    id={weekday.value}
                                    name="weekday"
                                    value={weekday.value}
                                    className="form-check-input visually-hidden"
                                    checked={isWeekdaySelected(weekday.value)}
                                    onChange={handleWeekdayChange}
                                    />
                                <label
                                    htmlFor={weekday.value}
                                    className={`form-check-label rounded-pill border me-2 ${isWeekdaySelected(weekday.value) ? 'checked' : ''}`}
                                >
                                    {weekday.label}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
            <style jsx>{`
                .list-group-item,
                .form-check-label {
                display: inline-block;
                font-size: 0.9rem !important;
                padding: 0.5rem 1rem;
                border-radius: 1rem;
                background-color: transparent;
                color: black;
                }
                
                .form-check-input:checked + .form-check-label {
                background-color: var(--cobee-background-dark);
                color: white;
                }
            `}</style>
        </div>
    );
};

export default WeekdaySelector;
