
import React, { useState, useEffect, useContext } from 'react';
import ReactLoading from 'react-loading';
import { MyContext } from './Context';
import ImageSlider from './ImageSliderNew';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChair, faCube, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

function OfferSection() {
    const { state, setState } = useContext(MyContext);
    const [isLoading, setIsLoading] = useState(false);
    const [searchResultOffer, setSearchResultOffer] = useState([]);

    // Define cityOffer, districtOffer, and branchOffer as state variables
    const [cityOffer, setCityOffer] = useState("Berlin");
    const [districtOffer, setDistrictOffer] = useState("Mitte");
    const [branchOffer, setBranchOffer] = useState("hairStudio");
    
    const handleSubmit = (event) => {
        if (event) event.preventDefault();

        setSearchResultOffer('Performing search...');

        let url = `${process.env.REACT_APP_BASE_URL_API}/search?city=${cityOffer}&district=${districtOffer}&branch=${branchOffer}`;

        console.log('Fetching search results:', url); // Debugging: Log the URL
        setIsLoading(true);
        setSearchResultOffer([]);

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                console.log('Search results received:', data);
                console.log('Updated search results:', Array.isArray(data) ? data : []);
                setTimeout(() => {
                    setSearchResultOffer(Array.isArray(data) ? data : []);
                    setState(Array.isArray(data) ? data : []);
                    setIsLoading(false);
                    //navigate('/'); // Redirect to the home page
                }, 1000);
            })
            .catch((error) => {
                console.error('Error retrieving search results:', error);
                setSearchResultOffer([]);
            });
    };

    useEffect(() => {
        handleSubmit();
        // setSearchResultOffer([]);
        // setIsLoading(true);

        // // Simulating an asynchronous search request
        // setTimeout(() => {
        //     const mockData = [
        //         // Mock search results
        //     ];
        //     setSearchResultOffer(mockData);
        //     setIsLoading(false);
        // }, 2000);
    }, [cityOffer, districtOffer, branchOffer]); // Add cityOffer, districtOffer, and branchOffer as dependencies

    return (
        <>
            <div className="full-width">
                {isLoading ? (
                    <div className="row">
                        <div className="d-flex justify-content-center align-items-center">
                            <ReactLoading type="spin" color="#000000" height={50} width={50} />
                        </div>
                    </div>
                ) : Array.isArray(state) && state.length > 0 ? (
                    <div className="overflow-auto w-100" style={{ height: '400px', overflowY: 'auto', overflowX: 'hidden' }}>
                        <div className="d-flex flex-nowrap">
                            <div className="col mx-4" style={{ minWidth: 250, maxWidth: 300 }}>
                                <div className="card">
                                    <div className="image-slider rounded-5" style={{ height: 250, backgroundImage: `url("../cobee-halftone-orange-blue.png")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center' }}>
                                        <h3 className='text-white'>
                                            Angebote
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ms-4" style={{ height: '40px' }}>
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                            </svg>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            {state.map((result, index) => (
                                <div key={result._id} className="col me-4" style={{ minWidth: 250, maxWidth: 300 }}>
                                    <div className="card">
                                        {result.images &&
                                            result.images.length > 0 && (
                                                <ImageSlider
                                                    id={`slider_${result._id}`}
                                                    _id={`${result._id}`}
                                                    images={result.images}
                                                />
                                            )}
                                        {/* <img src={result.image_url} className="rounded-xxl" alt={result.title} /> */}
                                        <a href={`/entity/${result._id}`} className="card-link">
                                            <div className="card-body px-1 py-2">
                                                <div className="card-title">{result.title}</div>
                                                <div className='card-text'>
                                                    <div className='small opacity-50'><FontAwesomeIcon icon={faLocationDot} className='opacity-25 me-2' /> {result.city}, {result.district} </div>
                                                    <div className='small opacity-50'><FontAwesomeIcon icon={faChair} className='opacity-25 me-2' /> Arbeitsplatz</div>
                                                </div>
                                                {/* <p className="card-text">{result.description}</p> */}
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="text-dark py-2 text-center">Keine Angebote in deiner Region - Sei der erste Anbieter und trage dein Cobee Space jetzt ein!</div>
                )}
            </div>
        </>
    );
}

export default OfferSection;
