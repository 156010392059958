import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import './SliderGallery.css';

SwiperCore.use([FreeMode, Navigation, Thumbs]);

export default function SliderGallery({ _id, images }) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [swiperKey, setSwiperKey] = useState(1);

    useEffect(() => {
        if (thumbsSwiper && thumbsSwiper.current) {
            thumbsSwiper.current.update();
        }
    }, [thumbsSwiper]);


    return (
        <div className=''>
            <Swiper
                key={`slider_${swiperKey}`}
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                    aspectRatio: '19/9', // Set the aspect ratio to 3:2
                }}
                spaceBetween={0}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                className="mySwiper2 rounded-top-4"
            >
                {images.map((image, index) => (
                    <SwiperSlide key={`slide_${_id}_${index}`}>
                        <img src={process.env.REACT_APP_BASE_URL_API + `/uploads/${_id}/${image}`} alt={`Slide ${index + 1}`} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
                key={`thumbs_${swiperKey}`}
                onSwiper={setThumbsSwiper}
                spaceBetween={2}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                className="mySwiperGallery rounded-bottom-4"
                style={{
                    // aspectRatio: '16/9', // Set the aspect ratio to 3:2
                }}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={`thumbnail_${_id}_${index}`}>
                        <img src={process.env.REACT_APP_BASE_URL_API + `/uploads/${_id}/${image}`} alt={`Slide ${index + 1}`} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
