import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='container mt-5'>
      <h1>Datenschutz</h1>

      <h3>Datenschutzerklärung für die Nutzung von Cobee.me</h3>

      <p>Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend "Daten") im Rahmen der Nutzung der Plattform Cobee.me (nachfolgend "Plattform") auf.</p>

      <strong>Verantwortliche Stelle:</strong>

      <bold>Cobee GbR i.G.</bold>
      <p>Rykestraße 52
      10405 Berlin
      E-Mail: datenschutz@cobee.me</p>

      <h4>1. Arten der verarbeiteten Daten:</h4>
      <p>Nutzerdaten (z.B. Name, Anschrift, E-Mail-Adresse)
      Kommunikationsdaten (z.B. IP-Adresse, Geräteinformationen)
      Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie)</p>

      <h4>2. Zweck der Verarbeitung:</h4>
      <p>Die Verarbeitung der Daten erfolgt zur Erfüllung des Vermittlungsvertrags zwischen Cobee.me und den Nutzern, insbesondere zur Vermittlung von ausgestatteten Arbeitsplätzen.</p>

      <h4>3. Rechtsgrundlagen der Verarbeitung:</h4>
      <p>Die Verarbeitung der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO (Erfüllung eines Vertrags), sowie ggf. auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO (berechtigtes Interesse) für statistische Auswertungen und Optimierung der Plattform.</p>

      <h4>4. Übermittlung von Daten an Dritte:</h4>
      <p>Eine Übermittlung von Daten an Dritte erfolgt nur im Rahmen der Erfüllung des Vermittlungsvertrags. Dies kann die Übermittlung von Daten an Vermieter und Mieter umfassen.</p>

      <h4>5. Speicherdauer:</h4>
      <p>Die Daten werden für die Dauer des Vermittlungsvertrags sowie darüber hinaus nach den gesetzlichen Aufbewahrungsfristen gespeichert.</p>

      <h4>6. Ihre Rechte:</h4>
      <p>Sie haben das Recht, Auskunft über Ihre gespeicherten Daten zu verlangen.
      Sie haben das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer Daten.
      Sie haben das Recht, Widerspruch gegen die Verarbeitung Ihrer Daten einzulegen.
      Sie haben das Recht auf Datenübertragbarkeit.</p>

      <p>Für die Ausübung dieser Rechte sowie für Fragen zum Datenschutz können Sie sich unter der oben genannten Adresse oder per E-Mail an datenschutz@cobee.me wenden.</p>

      <h4>7. Beschwerderecht:</h4>
      <p>Sie haben das Recht, sich bei der zuständigen Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer Daten gegen datenschutzrechtliche Bestimmungen verstößt.</p>

      <h4>8. Verwendung von Cookies:</h4>
      <p>Die Plattform Cobee.me verwendet Cookies, um die Nutzung der Website zu analysieren und das Angebot zu verbessern. Cookies sind kleine Textdateien, die auf Ihrem Endgerät gespeichert werden. Einige der von uns verwendeten Cookies werden nach dem Ende der Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns, Ihren Browser beim nächsten Besuch wiederzuerkennen (persistente Cookies).</p>

      <h4>8.1 Google Analytics:</h4>
      <p>Diese Website nutzt Google Analytics, einen Webanalysedienst der Google Inc. ("Google"). Google Analytics verwendet Cookies, um eine Analyse der Benutzung der Website durch Sie zu ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Wir haben jedoch die IP-Anonymisierung auf dieser Website aktiviert, sodass Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt wird. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen.</p>

      <h4>8.2 Google Tag Manager:</h4>
      <p>Die Plattform verwendet auch den Google Tag Manager. Dieser Dienst ermöglicht es, Website-Tags über eine Oberfläche zu verwalten. Der Tag Manager selbst (der die Tags implementiert) ist eine cookielose Domain und erfasst keine personenbezogenen Daten. Der Tag Manager sorgt für die Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen können. Der Google Tag Manager greift nicht auf diese Daten zu. Wenn auf Domain- oder Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für alle</p>

    </div>
  );
};

export default PrivacyPolicy;
