import React, { useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation, useParams } from 'react-router-dom';
import WebFont from 'webfontloader';

import AddEntityScreen from './screens/AddEntityScreen';
import BookingScreen from './screens/BookingScreen';
import BookingSingleScreen from './screens/BookingSingleScreen';
import BookingsScreen from './screens/BookingsScreen';
import DashboardScreen from './screens/DashboardScreen';
import EntityScreen from './screens/EntityScreen.js';
import Home from './screens/Home';
import LoginScreen from './screens/LoginScreen';
import ProfileScreen from './screens/ProfileScreen';
import RegisterScreen from './screens/RegisterScreen';
import TermsConditions from './screens/TermsConditions';
import PrivacyPolicy from './screens/PrivacyPolicy';
import Imprint from './screens/Imprint';
import MietereInformation from './screens/MietereInformation';
import VermietereInformation from './screens/VermietereInformation';
import EmailVerificationScreen from './screens/EmailVerificationScreen';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { MyProvider } from './components/Context';
import Footer from './components/Footer';
import LoadingSplashScreen from './components/LoadingSplashScreen';
import Navbar from './components/Navbar';
import NotFound from './components/NotFound';
import SearchForm from './components/SearchForm';
import SearchResults from './components/SearchResults';

import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chat from './components/Chat';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

WebFont.load({
	google: {
		families: ['Fira Sans:400,500,700,800'] // Specify the desired font and weights
		// families: ['Geologica:100,200,400,500,600,700,800'] // Specify the desired font and weights//
	}
});

const TransitionSwitch = () => {

	const location = useLocation();
	const [user, setUser] = useState(null);

	// console.log('API: ' + process.env.REACT_APP_BASE_URL_API);
	// console.log('docroot: ' + process.env.REACT_APP_DOCUMENT_ROOT);

	useEffect(() => {
		const getUser = () => {
			fetch(process.env.REACT_APP_BASE_URL_API + '/login/success', {
				method: 'GET',
				credentials: 'include',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Access-Control-Allow-Credentials': 'true',
				}
			})
				.then((response) => {
					if (response.status === 200) return response.json();
					throw new Error('Authentication has been failed!');
				})
				.then((resObject) => {
					setUser(resObject.user);
					console.log('Set User');
					console.log(resObject.user);
					console.log(user); // Check the value of the user state
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getUser();
	}, []);

	return (
		<TransitionGroup>
			<CSSTransition key={location.key} classNames="fade" timeout={300}>
				<Routes location={location}>
					<Route path="/" element={<Home />} /> <Route path="/register" element={<RegisterScreen />} />
					<Route path="/login" element={user ? <Navigate to="/" /> : <LoginScreen />} />
					{/* <Route path="/entity/:id" component={EntityScreen} /> */}
					{/* <Route path="/entity/:id" element={<EntityScreen />} /> */}
					<Route path="/entity/:id" element={<EntityScreen />} />
					{/* <Route path="/entity/:id" render={(props) => <EntityScreen {...props} />} /> */}
					{/* <Route path="/login" element={<LoginScreen />} /> */}
					<Route path="/add" element={<AddEntityScreen />} />
					<Route path="/dashboard" element={<DashboardScreen />} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
					<Route path="/terms-conditions" element={<TermsConditions />} />
					<Route path="/imprint" element={<Imprint />} />
					<Route path="/vermieter-information" element={<VermietereInformation />} />
					<Route path="/mieter-information" element={<MietereInformation />} />
					<Route path="/booking" element={<BookingScreen />} />
					{/* <Route path="/bookings" element={<BookingsScreen />} /> */}
					<Route path="/bookings" element={user && user.role === 'sales' ? <BookingsScreen /> : <Navigate to="/login" />} />
					{/* <Route path="/bookings/:id" element={<BookingSingleScreen />} /> */}
					<Route path="/bookings/:id" element={user && user.role === 'sales' ? <BookingSingleScreen /> : <Navigate to="/login" />} />
					<Route path="/profile" element={<ProfileScreen />} />
					<Route path="/verify-email/:vid/:email" element={<EmailVerificationScreen />} />
					<Route Component={NotFound} />
				</Routes>
			</CSSTransition>
			<Chat /> {/* Include the Chat component here */}
		</TransitionGroup>
	);
};

function App() {
	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		// Simulate a delay to demonstrate the loading process
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	}, []);

	useEffect(() => {
		const getUser = () => {
			fetch(process.env.REACT_APP_BASE_URL_API + '/login/success', {
				method: 'GET',
				credentials: 'include',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Access-Control-Allow-Credentials': 'true',
				}
			})
				.then((response) => {
					if (response.status === 200) return response.json();
					throw new Error('Authentication has been failed!');
				})
				.then((resObject) => {
					setUser(resObject.user);
					console.log('Set User');
					console.log(resObject.user);
					console.log(user); // Check the value of the user state
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getUser();

		const script = document.createElement('script');

		script.src = "https://cloud.ccm19.de/app.js?apiKey=ad184969da23e23b66be88753c22b4bf13e884cd20577ca6&amp;domain=65a59d0fccd776d9580fa1b2";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, []);

	return (
		<MyProvider>
			{isLoading ? (
				<LoadingSplashScreen />
			) : (
				<Router>
					<Navbar user={user} />
					{/* <ProfileSection user={user} /> */}
					{/* <Route path="/" element={<LoginScreen />} /> */}
					<div className="App">
						<TransitionSwitch />
						<Footer />
					</div>
				</Router>
			)}
		</MyProvider>
	);
}

export default App;
