import React, { useState, useEffect, useContext } from 'react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

const libraries = ["places"];

const GooglePlaces = ({ onCityChange, handleSubmit}) => {
    const [autocomplete, setAutocomplete] = React.useState(null);
    const [city, setCity] = React.useState("");
    const [url, setUrl] = React.useState(""); // Neue Zustandsvariable für die URL

    const onLoad = (autocompleteInstance) => {
        console.log("Autocomplete is loaded");
        setAutocomplete(autocompleteInstance);
    };

    const onPlaceChanged = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace();
            if (place.address_components) {
                const city = place.address_components.find(component => component.types.includes("locality") || component.types.includes("administrative_area_level_1"))?.long_name;
                if (city) {
                    console.log("Full City from Autocomplete:", city);
                    onCityChange(city); // This should pass the full city name
                    // setCity(city); // This should pass the full city name
                    // handleSubmit(); // Trigger the search when a place is selected
                } else {
                    console.log("Place selected does not contain city information.");
                }
            } else {
                console.log("No address components found in place object.");
            }
        } else {
            console.log("Autocomplete not initialized.");
        }
    };
    
    
    return (
        <LoadScript googleMapsApiKey="AIzaSyDC_ijosAoEJQSCiht1wmLSdmrYVAoK8qY" libraries={libraries}>
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                <input
                    type="text"
                    placeholder="Deine Stadt"
                    // value={city}
                    // onChange={(e) => setCity(e.target.value)}
                    // onChange={(e) => onCityChange(city)}
                />
            </Autocomplete>
        </LoadScript>
    );
};

export default GooglePlaces;