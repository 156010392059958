import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL_API // Replace with your server's URL
});

const RegisterScreen = () => {
	const [ username, setusername ] = useState('');
	const [ useremail, setuseremail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ registrationSuccess, setRegistrationSuccess ] = useState(false);

	const handleRegister = async (e) => {
		e.preventDefault();

		try {
			const response = await axios.post(process.env.REACT_APP_BASE_URL_API + '/register', { username, useremail, password });
			console.log(response.data); // Handle the server response as needed
			setRegistrationSuccess(true); // Set registration success flag
		} catch (error) {
			console.error(error); // Handle error cases
		}
	};

	if (registrationSuccess) {
		// Redirect to the dashboard or any other desired page
		return <Navigate to="/dashboard" />;
	}

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-6">
					<div className="card mt-5">
						<div className="card-body">
							<h2 className="card-title text-center"> Register </h2>
							<form onSubmit={handleRegister}>
								<div className="mb-3">
									<input
										type="text"
										className="form-control"
										id="useremail"
										placeholder="E-Mail"
										value={useremail}
										onChange={(e) => {
											setuseremail(e.target.value);
											setusername(e.target.value);
										}}
									/>
								</div>
								<div className="mb-3">
									<input
										type="password"
										className="form-control"
										id="password"
										placeholder="Password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>
								<div className="d-grid">
									<button type="submit" className="btn btn-lg btn-primary">
										Register
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RegisterScreen;
