import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import axios from 'axios';
import reportWebVitals from './reportWebVitals';
import { SearchProvider } from './components/SearchContext';
import { Helmet } from 'react-helmet';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Your i18n configuration file
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'GTM-NF9KCG48' // Ersetzen Sie 'GTM-XXXXXXX' durch Ihre GTM-ID
}

TagManager.initialize(tagManagerArgs);

// const REACT_APP_BASE_URL_API = process.env.REACT_APP_BASE_URL_API;

// const instance = axios.create({
//     baseURL: REACT_APP_BASE_URL_API, // Replace with your server's URL
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <React.StrictMode>
            <I18nextProvider i18n={i18n}>
                <SearchProvider>
                    <Helmet>
                        <meta name="theme-color" content="#ecd96f" media="(prefers-color-scheme: light)" />
                        <meta name="theme-color" content="#0b3e05" media="(prefers-color-scheme: dark)" />
                    </Helmet>
                    <App />
                </SearchProvider>
            </I18nextProvider>
        </React.StrictMode>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();