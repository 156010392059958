import React from 'react';
import { useTranslation } from 'react-i18next';

const BookingListItem = ({ booking }) => {

    const { t } = useTranslation();
    
    const bookingDuration = booking.bookingDuration;
    console.log(t(bookingDuration));

   
    return (
        <li className="list-group-item">
            <div className="d-row justify-content-between align-items-center">
                {/* <div className="row"></div> */}
                <div className='row'>
                    <div className='col-8'>
                        <div className='col-12'>
                        <a className="text-decoration-none fw-bold" href={`/bookings/${booking._id}`}>
                            {booking.firstName} {booking.lastName}
                        </a>
                            <div className='row'>
                                <div className='col-6'>
                                    {booking.date.day}.{booking.date.month}.{booking.date.year}
                                    <label className="small opacity-50 d-block">Beginn</label>
                                </div>
                                <div className='col-6'>
                                    {t(`${bookingDuration}`)}
                                    <label className="small opacity-50 d-block">Dauer</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-auto ms-auto my-auto'>
                        <a className='btn btn-small btn-primary'>Weiter</a>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default BookingListItem;
