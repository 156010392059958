import React, { useState, useEffect, useCallback } from 'react';

const DayRateForm = ({
    initialDailyRate,
    onInitialDailyRateChange,
    additionalDailyRates,
    onAdditionalDailyRatesChange,
    additionalDailyRatesArray,
    onDayRatesChange,
    selectedWeekdays,
    formData,
    setFormData,
}) => {

    const [dayRate, setDayRate] = useState([]);
    const [dayRates, setDayRates] = useState([]);
    const [newRates, setNewRates] = useState([]);
   
    // Define initial percentage decreases for each day
    const percentageDecreases = [20, 25, 30, 35, 40, 45, 50];

    // Function to calculate the rate for a given day
    const calculateRate = (index, rate) => {
        return Math.round(rate * (index + 1) * 4 * (1 - percentageDecreases[index] / 100));
    };

    const renderInputFields = () => (
        <div className="form-group row mb-3">
            <label htmlFor="initial-daily-rate" className="col-sm-5 col-form-label my-auto fw-bold">Tagespreis</label>
            <div className="col-sm-7">
                <div className='input-group'>
                    <input
                        type="number"
                        className="form-control text-center"
                        id="initial-daily-rate"
                        value={initialDailyRate}
                        onChange={(e) => {
                            onInitialDailyRateChange(Number(e.target.value))
                            setDayRate(Number(e.target.value)) 
                            // setFormData(prevFormData => {
                            //     return { ...prevFormData, rateDay: Number(e.target.value) };
                            // });
                            console.log(formData)
                        }}
                        // {...console.log('initialDailyRate:', formData)}
                    />
                    <div className="input-group-append"><span className="input-group-text">€ / Tag</span></div>
                </div>
            </div>
        </div>
    );

    const renderAdditionalInputFields = () => (
        selectedWeekdays.map((weekday, index) => (
            <div className="form-group row mb-3" key={index}>
                <label htmlFor={`additional-daily-rate-${index}`} className="col-sm-5 col-form-label my-auto">
                    {`Monatspreis bei ${index + 1} ${index > 0 ? 'Tagen' : 'Tage'} pro Woche`}
                </label>
                <div className="col-sm-7">
                    <div className='input-group'>
                        <input
                            type="number"
                            className="form-control text-center"
                            id={`additional-daily-rate-${index}`}
                            value={additionalDailyRates[index] || ''}
                            onChange={(e) => {
                                onAdditionalDailyRatesChange(index, Number(e.target.value))
                                getAdditionalDailyRatesValues();
                                // setFormData(prevFormData => {
                                //     const newFormData = { ...prevFormData };
                                //     (Array.isArray(additionalDailyRates) ? additionalDailyRates : []).forEach((rate, index) => {
                                //         newFormData[`rateDay${index + 1}`] = rate;
                                //     });
                                //     return newFormData;
                                // });
                                // console.log(formData)
                            }}
                            // {...console.log('onAdditionalDailyRatesChange:', formData)}
                        />
                        <div className="input-group-append"><span className="input-group-text">€ / Monat</span></div>
                    </div>
                </div>
            </div>
        ))
    ); 
    
    const getAdditionalDailyRatesValues = () => {
        const additionalDailyRatesValues = [];
        const inputs = document.querySelectorAll('input[id^="additional-daily-rate-"]');
        inputs.forEach((input) => {
            additionalDailyRatesValues.push(input.value);
        });
        return additionalDailyRatesValues;
    };
    
    // Update additionalDailyRates based on initialDailyRate and selectedWeekdays
    useEffect(() => {
        
        console.log('initialDailyRate on useEffect:', initialDailyRate);
        console.log('selectedWeekdays on useEffect:', selectedWeekdays);
        console.log('additionalDailyRates on useEffect', additionalDailyRates);
        console.log('additionalDailyRatesArray on useEffect', additionalDailyRatesArray);

        if (initialDailyRate && selectedWeekdays && selectedWeekdays.length > 0) {
            const newRates = selectedWeekdays.map((weekday, index) => calculateRate(index, initialDailyRate));
            const ratesChanged = newRates.some((rate, index) => rate !== additionalDailyRates[index]);
            console.log('ratesChanged', ratesChanged);

            if (ratesChanged) {
                const newRatesAsNumbers = newRates.map(rate => parseInt(rate, 10));
                onAdditionalDailyRatesChange(newRatesAsNumbers);
                setDayRates(newRatesAsNumbers);
                onDayRatesChange(newRatesAsNumbers);
                setFormData(prevFormData => ({
                    ...prevFormData,
                    rateDay: initialDailyRate,
                }));
                // const additionalDailyRatesArray = Object.values(additionalDailyRates);
                // console.log('additionalDailyRatesArray', additionalDailyRatesArray);
                // setFormData(prevFormData => {
                //     return { ...prevFormData, additionalDailyRates: additionalDailyRatesArray };
                // });
            }
            console.log(formData)
        }
    }, [initialDailyRate, selectedWeekdays]);

    return (
        <div>
            {renderInputFields()}
            <h6>Monatspreise</h6>
            <p className='text-muted'>Die hier angegeben Preise sind Vorschläge. Du kannst die Preis überschreiben oder den Tagespreis anpassen.</p>
            <hr className='my-4'></hr>
            {renderAdditionalInputFields()}
        </div>
    );
};

export default DayRateForm;
