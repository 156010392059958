import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL_API // Replace with your server's URL
});

const LoginScreen = () => {
	const [username, setusername] = useState('');
	const [useremail, setuseremail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [LoginSuccess, setLoginSuccess] = useState(false);

	const handleLogin = async () => {
		setError('');

		if (!username || !password) {
			setError('Please enter both useremail and password.');
			return;
		}

		try {
			const response = await instance.post(process.env.REACT_APP_BASE_URL_API + '/login', { username, password });
			console.log(response.data);
			setLoginSuccess(true); // Set registration success flag
		} catch (error) {
			setError('Invalid useremail or password.');
			console.error(error);
		}
	};

	if (LoginSuccess) {
		// Redirect to the dashboard or any other desired page
		return <Navigate to="/dashboard" />;
	}

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-6">
					<div className="card mt-5">
						<div className="card-body">
							<h2 className="card-title text-center"> Sign In </h2>
							{error && <p className="text-danger"> {error} </p>}
							<div className="mb-3">
								<input
									type="text"
									className="form-control"
									placeholder="E-Mail"
									value={username}
									onChange={(e) => setusername(e.target.value)}
								/>
							</div>
							<div className="mb-3">
								<input
									type="password"
									className="form-control"
									placeholder="Password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<div className="d-flex justify-content-start align-items-center">
								<button className="btn btn-lg btn-primary" onClick={handleLogin}>
									Login
								</button>
								<div className="col-auto my-auto ms-4">
									Sign Up <a href="register"> Here </a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginScreen;
