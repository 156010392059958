import React from 'react';

const DashboardScreen = () => {
    return (
        <div className="container-fluid">
            <h1>Welcome to the Dashboard</h1>
            {/* Add your dashboard content here */}
        </div>
    );
};

export default DashboardScreen;
