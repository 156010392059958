import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SearchForm from '../components/SearchForm';
import Autocomplete from 'react-autocomplete';
import { QuestionCircleIcon } from '@heroicons/react/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

const Navbar = ({ user }) => {
	const logout = () => {
		window.open(process.env.REACT_APP_BASE_URL_API + '/auth/logout', '_self');
	};

	const [searchQuery, setSearchQuery] = useState('');

	const handleSearch = (query) => {
		setSearchQuery(query);
	};

	return (
		<div className="navbar">
			<div className="fixed-top border-bottom">
				<nav id="nav-main" className="navbar navbar-expand-lg">
					<div className="container-xl d-flex justify-content-between align-items-center">
						{/* Left Logo */}
						<a className="navbar-brand md d-md-block d-flex justify-content-center" href="/">
							<img
								src="../cobee_logo_new.png"
								alt="logo"
								className={` ${window.innerWidth <= 768 ? 'me-4' : ''}`}
								style={{ marginLeft: window.innerWidth <= 768 ? 'auto' : '0' }}
							/>
						</a>

						<div className="header-actions d-flex align-items-center">
							<div className="top-nav-widget" />

							<div className="d-none d-lg-block ms-1 ms-md-2 top-nav-search-lg" />

							{/* <button
								className="btn btn-lg btn-outline-secondary d-lg-none ms-1 ms-md-2 top-nav-search-md"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapse-search"
								aria-expanded="false"
								aria-controls="collapse-search"
							>
								<i className="fa-solid fa-magnifying-glass" />
								<span className="visually-hidden-focusable">Search</span>
							</button> */}

							<button
								className="btn d-lg-none ms-1 ms-md-2"
								type="button"
								data-bs-toggle="offcanvas"
								data-bs-target="#offcanvas-navbar"
								aria-controls="offcanvas-navbar"
							>
								{/* <i className="fa-solid fa-bars" />
								<span className="visually-hidden-focusable">Menu</span> */}
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="black" className="w-6 h-6" style={{ height: '25px' }}>
									<path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
								</svg>

							</button>
						</div>
						{/* Centered SearchForm */}
						<div className="col-12 col-md-auto mx-auto">
							<SearchForm onSearch={handleSearch} className="mx-auto" />
						</div>

						{/* Right Offcanvas Navbar */}
						<div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvas-navbar">
							<div className="offcanvas-header bg-light">
								<span className="h5 mb-0">Menü</span>
								<button
									type="button"
									className="btn-close text-reset"
									data-bs-dismiss="offcanvas"
									aria-label="Close"
								/>
							</div>
							<div className="offcanvas-body">
								<ul id="bootscore-navbar" className="navbar-nav ms-auto ">
									{/* <li
										id="menu-item-51"
										className="menu-item menu-item-type-custom menu-item-object-custom nav-item nav-item-51"
									>
										<a href="../#" className="nav-link ">
											<small className="text-muted">
												Für Hilfe hier klicken 
												<FontAwesomeIcon
													icon={faCircleQuestion}
													className="h-6 w-6 text-gray-500"
												/>
											</small>
										</a>
									</li> */}
									{/* <li
										id="menu-item-13"
										className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-13"
									>
										<a href="/" className="nav-link ">
											Arbeitsplatz Suchen
										</a>
									</li> */}
									<li
										id="menu-item-13"
										className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-13"
									>
										<a href="../add/" className="nav-link ">
											Arbeitsplatz Vermieten
										</a>
									</li>
									{/* <hr></hr>
									<li
										id="menu-item-13"
										className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-13"
									>
										<a href="../add/" className="nav-link ">
											Support
										</a>
									</li> */}
								</ul>
							</div>
						</div>

						
					</div>
				</nav>
				<div className="collapse container d-lg-none" id="collapse-search" />
			</div>

			{/* <span className="logo">
                <Link className="link" to="/">
                    <img src="cobee_logo.png" alt="Cobee.me" className="ms-4"></img>
                </Link>
            </span>
            {
                user ? (
                    <ul className="list">
                        <li className="listItem">
                            <img src={user?.photos[0]?.value || ''} alt="" className="avatar" />
                        </li>
                        <li className="listItem">{user.displayName || ''}</li>
                        <li className="listItem" onClick={logout}>Logout</li>
                    </ul>
                ) : null
            }
            {
                !user && (
                    <ul className="list">
                        <li className="listItem">
                            <Link className="link" to="/login">Login</Link>
                        </li>
                        <li className="listItem">
                            <Link className="link" to="/register">Register</Link>
                        </li>
                    </ul>
                )
            } */}
		</div>
	);
};

export default Navbar;
